import axios from 'axios';

const API_URL = '/api/products';


// Create a new product
const createProduct = async (productData, token) => {
  try {
    const response = await axios.post(API_URL, productData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//The added function

// Fetch a list of products
const fetchProducts = async () => {
  try {
    const response = await axios.get(API_URL);
    console.log(response)
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Update a product
const updateProduct = async (productId, productData, token) => {
  try {
    const response = await axios.put(`${API_URL}${productId}`, productData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Delete a product
const deleteProduct = async (productId, token) => {
  try {
    const response = await axios.delete(`${API_URL}/${productId}`)
    return response.data;
  } catch (error) {
    throw error;
  }
};

//********************FetchProduct BId */
const fetchProductById = async (productId) => {
  try {
    const response = await axios.get(`${API_URL}/${productId}`)
    console.log(response.data)
    return response.data;

  } catch (error) {
    throw error;
  }
};

export function fetchProductsByFilters(filter, sort, pagination) {
  const queryParams = [];
  for (let key in filter) {
    const categoryValues = filter[key];
    if (categoryValues.length) {
      queryParams.push(`${key}=${encodeURIComponent(categoryValues.join(','))}`);
    }
  }

  // Use subCategory consistently
  if (filter.subCategory && filter.subCategory.length) {
    queryParams.push(`subCategory=${encodeURIComponent(filter.subCategory.join(','))}`);
  }

  // Add sort parameters to the array
  for (let key in sort) {
    queryParams.push(`${key}=${sort[key]}`);
  }

  // Add pagination parameters to the array
  for (let key in pagination) {
    queryParams.push(`${key}=${pagination[key]}`);
  }

  // Combine all query parameters into a single string
  const queryString = queryParams.join('&');

  return new Promise(async (resolve) => {
    try {
      const response = await axios.get(`api/products/filter?${queryString}`);
      const totalItems = response.headers['x-total-count'];
      const data = response.data;

      resolve({ data: { products: data, totalItems: +totalItems } });
    } catch (error) {
      console.error('Error fetching products:', error);
      resolve({ data: { products: [], totalItems: 0 } });
    }
  });
}

// export function fetchProductsByFilters(filter, sort, pagination) {
//   const queryParams = [];
//   for (let key in filter) {
//     const categoryValues = filter[key];
//     if (categoryValues.length) {
//       // Use encodeURIComponent to handle special characters in values
//       queryParams.push(`${key}=${encodeURIComponent(categoryValues.join(','))}`);
//     }
//   }

//   // Add sort parameters to the array
//   for (let key in sort) {
//     queryParams.push(`${key}=${sort[key]}`);
//   }

//   // Add pagination parameters to the array
//   for (let key in pagination) {
//     queryParams.push(`${key}=${pagination[key]}`);
//   }

//   // Combine all query parameters into a single string
//   const queryString = queryParams.join('&');

//   return new Promise(async (resolve) => {
//     try {
//       // Use axios.get directly, no need for response.json()
//       const response = await axios.get(`api/products/filter?${queryString}`);
//       const totalItems = response.headers['x-total-count'];
//       const data = response.data;

//       resolve({ data: { products: data, totalItems: +totalItems } });
//     } catch (error) {
//       console.error('Error fetching products:', error);
//       resolve({ data: { products: [], totalItems: 0 } });
//     }
//   });
// }

// export function fetchProductsByFilters(filter, sort, pagination) {


//   let queryString = '';
//   for (let key in filter) {
//     const categoryValues = filter[key];
//     if (categoryValues.length) {
//       queryString += `${key}=${categoryValues}&`;
//     }
//   }
//   for (let key in sort) {
//     queryString += `${key}=${sort[key]}&`;
//   }
//   for (let key in pagination) {
//     queryString += `${key}=${pagination[key]}&`;
//   }


//   return new Promise(async (resolve) => {
//     const response = await axios.get(
//       'api/products/filter?' + queryString
//     );
//     const data = await response.json();
//     const totalItems = await response.headers.get('X-Total-Count');
//     resolve({ data: { products: data, totalItems: +totalItems } });
//   });
// }

export function fetchCategories() {
  return new Promise(async (resolve) => {
    const response = await fetch('/categories');
    const data = await response.json();
    resolve({ data });
  });
}

// const fetchCategories = async (token) => {
//   const response = await axios.get('/api/categories');
//   return response.data;
// };
const fetchSubCategories = async (token) => {
  const response = await axios.get('/api/subCategories');
  return response.data;
};

const productService = {
  createProduct,
  fetchProducts,
  updateProduct,
  deleteProduct,
  fetchProductById,
  fetchCategories,
  fetchSubCategories,
  fetchProductsByFilters
};

export default productService;
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Box, TextField, Button, MenuItem, Select, FormControl, InputLabel,} from '@mui/material';
import { createOrder } from '../../../features/orders/orderSlice';
import productService from '../../../features/products/productService'; // Update the path

const AddOrderForm = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    customerId: '',
    productName: '', 
    quantity: 0,
    cartTotalPrice: 0,
    status: 'Open',
  });

  const [products, setProducts] = useState([]);

  const dispatch = useDispatch();

  const { customerId, productName, quantity, cartTotalPrice, status } = formData;

  useEffect(() => {
    const fetchProductsData = async () => {
      try {
        const productsData = await productService.fetchProducts();
        setProducts(productsData);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProductsData();
  }, []);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(createOrder(formData));

    // Reset form data
    setFormData({
      customerId: '',
      productName: '',
      quantity: 0,
      cartTotalPrice: 0,
      status: 'Open',
    });

    onClose(); // Close the modal after submission
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'black', p: 4, width: 400, }}>
        <form onSubmit={handleSubmit}>
          <TextField
            className="input1"
            label="Customer ID"
            id="customerId"
            name="customerId"
            value={customerId}
            onChange={onChange}
            fullWidth
          />
         
          <FormControl className="input1" fullWidth>
            <InputLabel>Product</InputLabel>
            <Select
              label="Product"
              name="productName"
              value={productName}
              onChange={onChange}
            >
              {products.map((product) => (
                <MenuItem key={product.productName} value={product.productName}>
                  {product.productName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            className="input1"
            label="Quantity"
            name="quantity"
            type="number"
            value={quantity}
            onChange={onChange}
            fullWidth
          />
          <TextField
            className="input1"
            label="cartTotalPrice"
            name="cartTotalPrice"
            type="number"
            value={cartTotalPrice}
            onChange={onChange}
            fullWidth
          />
          <Button type="submit" variant="contained" color="primary">
            Add Order
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default AddOrderForm;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css'

function Navbar() {
    const [navOpen, setNavOpen] = useState(false);
    const [scrolling, setScrolling] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 50) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const navbarClasses = `nav ${scrolling ? 'scrolling' : ''}`;
  return (
    <div className='nav'>
        <div className="nav-container">
            <div className="navbar">
                <div className="logo">IVER</div> 
   
  
                <div className="menu-toggle" onClick={() => setNavOpen(!navOpen)}>
                    <div className={navOpen ? "hamBox hamBoxOpen" : "hamBox"}>
                        <span className={navOpen ? "lineTop spin" : "lineTop"}></span>
                        <span className={navOpen ? "lineBottom spin" : "lineBottom"}></span>
                    </div>
                </div>
                
            </div>
            <div className="nav-overlay" style={{
          top: navOpen ? "0" : "-100%",
          transitionDelay: navOpen ? "0s" : "0s"
        }}>
            <ul className="nav-links">
                <li className="nav-item">
                    <Link to='/' onClick={() => setNavOpen(!navOpen)}
                     style={{
                        top: navOpen ? '0' : '120px',
                        transitionDelay: navOpen ? "0.8s" : "0s",
                    }}>
                        Home
                    </Link>
                    <div className="nav-item-wrapper"></div>
                </li>
                <li className="nav-item">
                    <Link to='/Product' onClick={() => setNavOpen(!navOpen)} 
                        style={{
                            top: navOpen ? '0' : '120px',
                            transitionDelay: navOpen ? "0.9s" : "0s",
                        }}>
                        Product
                    </Link>
                    <div className="nav-item-wrapper"></div>
                </li>
                <li className="nav-item">
                    <Link to='/about' onClick={() => setNavOpen(!navOpen)} 
                        style={{
                            top: navOpen ? '0' : '120px',
                            transitionDelay: navOpen ? "1s" : "0s",
                        }}>
                        About
                    </Link>
                    <div className="nav-item-wrapper"></div>
                </li>
                <li className="nav-item">
                    <Link to='/' 
                        style={{
                            top: navOpen ? '0' : '120px',
                            transitionDelay: navOpen ? "1.1s" : "0s",
                        }}>
                        Contact
                    </Link>
                    <div className="nav-item-wrapper"></div>
                </li>
            </ul>
            <div className="nav-footer">
                <div className="location"
                     style={{
                        bottom: navOpen ? "0" : "-20px",
                        opacity: navOpen ? "1" : "0",
                        transitionDelay : navOpen ? "1,2s" : "0s",
                     }}>
                    <span>Morocco, ON</span>
                </div>
                <div className="nav-social-media">
                    <ul>
                        <li>
                    <Link to={'/registerc'}
                     style={{
                        bottom: navOpen ? "0" : "-20px",
                        opacity: navOpen ? "1" : "0",
                        transitionDelay : navOpen ? "1,3s" : "0s",
                     }}>SignUp</Link>
                        </li>
                        <li>
                    <Link to={'/username'}
                     style={{
                        bottom: navOpen ? "0" : "-20px",
                        opacity: navOpen ? "1" : "0",
                        transitionDelay : navOpen ? "1,3s" : "0s",
                     }}>SignIn</Link>
                        </li>
                        <li>
                        <Link to={'/cart'}
                            style={{
                              bottom: navOpen ? "0" : "-20px",
                              opacity: navOpen ? "1" : "0",
                              transitionDelay : navOpen ? "1,4s" : "0s",
                                }}>Cart</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Navbar
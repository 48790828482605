import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  loginUser,
  createUser,
  logout
} from './authApi';

const initialState = {
  loggedInUserToken: null, 
  status: 'idle',
  error: null,
  userChecked: false,
  mailSent: false,
  passwordReset:false
};

export const createUserAsync = createAsyncThunk(
  'auth/createUser',
  async (userData) => {
    const response = await createUser(userData);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const loginUserAsync = createAsyncThunk(
  'auth/loginUser',
  async (loginInfo, { rejectWithValue }) => {
    try {
      const response = await loginUser(loginInfo);
      console.log(response.data.user);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const signOutAsync =  createAsyncThunk('auth/logout', async () => {
  await logout();
  localStorage.removeItem('user'); 
  localStorage.removeItem('Token'); 
  localStorage.removeItem('Role');
  localStorage.removeItem('msg'); 
 



  // localStorage.removeItem('Token'); 
  // localStorage.removeItem('Role'); 
console.log("logout succsufely ")
} )



//   try {
//     const response = await checkAuth();
//     return response.data;
//   } catch (error) {
//     console.log(error);
//   }
// });
// export const resetPasswordRequestAsync = createAsyncThunk(
//   'user/resetPasswordRequest',
//   async (email,{rejectWithValue}) => {
//     try {
//       const response = await resetPasswordRequest(email);
//       return response.data;
//     } catch (error) {
//       console.log(error);
//       return rejectWithValue(error);

//     }
//   }
// );

// export const resetPasswordAsync = createAsyncThunk(
//   'user/resetPassword',
//   async (data,{rejectWithValue}) => {
//     try {
//       const response = await resetPassword(data);
//       console.log(response);
//       return response.data;
//     } catch (error) {
//       console.log(error);
//       return rejectWithValue(error);

//     }
//   }
// );

// export const signOutAsync = createAsyncThunk(
//   'user/signOut',
//   async () => {
//     const response = await signOut();
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedInUserToken = action.payload;
      })
      .addCase(loginUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedInUserToken = action.payload;
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload;
      })
      .addCase(signOutAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signOutAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedInUserToken = null;
      })
      // .addCase(checkAuthAsync.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(checkAuthAsync.fulfilled, (state, action) => {
      //   state.status = 'idle';
      //   state.loggedInUserToken = action.payload;
      //   state.userChecked = true;
      // })
      // .addCase(checkAuthAsync.rejected, (state, action) => {
      //   state.status = 'idle';
      //   state.userChecked = true;
      // })
      // .addCase(resetPasswordRequestAsync.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(resetPasswordRequestAsync.fulfilled, (state, action) => {
      //   state.status = 'idle';
      //   state.mailSent = true;
      // })
      // .addCase(resetPasswordAsync.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(resetPasswordAsync.fulfilled, (state, action) => {
      //   state.status = 'idle';
      //   state.passwordReset = true;
      // })
      // .addCase(resetPasswordAsync.rejected, (state, action) => {
      //   state.status = 'idle';
      //   state.error = action.payload
      // })
  },
});

export const selectLoggedInUser = (state) => state.auth.loggedInUserToken;
export const selectError = (state) => state.auth.error;
export const selectUserChecked = (state) => state.auth.userChecked;
export const selectMailSent = (state) => state.auth.mailSent;
export const selectPasswordReset = (state) => state.auth.passwordReset;

// export const { } = authSlice.actions;
export const { reset } = authSlice.actions;

export default authSlice.reducer;

import React, { useState } from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from '../../../components/BackOfficeComponents/global/theme';
import Sidebar from '../../../components/BackOfficeComponents/global/Sidebar';
import Topbar from '../../../components/BackOfficeComponents/global/Topbar';
import EditProductForm from '../../../components/BackOfficeComponents/productComponents/EditProductForm';
import ProductTable from '../../../components/BackOfficeComponents/productComponents/ProductTable';
import '../Customers/customer.css'
const Products = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app2">
          <Sidebar isSidebar={isSidebar} />
          <main className="content2">
            <Topbar setIsSidebar={setIsSidebar} />
            <ProductTable  />

          </main>
        </div>
        <EditProductForm open={isEditModalOpen} onClose={() => setEditModalOpen(false)} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Products;
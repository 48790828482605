import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { Modal, Box, TextField, Button, MenuItem, Select, FormControl, InputLabel, Typography } from '@mui/material';
import {createProduct} from '../../../features/products/productSlice'
import '../../../pages/BackOffice/users/users.css'



const AddProductForm = ({ open, onClose }) => {
    const [imageFileName, setImageFileName] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    sku: '',
    price: 0,
    quantity: 0,
    productImage: null,
    description: '',
    subcategoryName: '',
    discountPrice: '',
  });

  const dispatch = useDispatch()
   
  const {name, sku, price, quantity, productImage, description, subcategoryName, discountPrice} = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }


  const handleSubmit = (event) => {
    event.preventDefault();
  
    dispatch(createProduct(formData));

  // Reset form data
  setFormData({
    name: '',
    sku: '',
    price: 0,
    quantity: 0,
    productImage: null,
    description: '',
    subcategoryName: '',
    discountPrice: '',
  });

  onClose(); // Close the modal after submission
  };

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      productImage: file,
    }));
    setImageFileName(file ? file.name : '');
  };
  

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'black', p: 4, width: 400, }}
      >
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <TextField className='input1' label="Name" id="name" name="name" value={name} onChange={onChange}  fullWidth  />
          <TextField className='input1' label="SKU" id='sku' name="sku" value={sku} onChange={onChange} fullWidth />
          <TextField className='input1' label="price" id='price' name="price" value={price} onChange={onChange} fullWidth />
          <TextField className='input1' label="quantity" id='quantity' name='quantity' value={quantity} onChange={onChange} fullWidth  />
          <TextField className='input1' label="description" id='description' name="description" value={description} onChange={onChange} fullWidth />
          <TextField className='input1' label="subcategoryName" id='subcategoryName' name="subcategoryName" value={subcategoryName} onChange={onChange} fullWidth />
          <TextField className='input1' label="discountPrice" id='discountPrice' name='discountPrice' value={discountPrice} onChange={onChange} fullWidth  />

          <input accept="image/*" id="contained-button-file" multiple type="file"  onChange={(e) => handleChangeImage(e)} style={{ display: 'none' }} />
          <label htmlFor="contained-button-file">
            <Button variant="contained" component="span" sx={{ marginTop: 2 }}>
              Upload Image
            </Button>
          </label>

          {/* Display the image file name */}
          {imageFileName && (
            <Typography variant="subtitle2" color="textSecondary" sx={{ marginLeft: 2 }}>
              {imageFileName}
            </Typography>
          )}
          <Button type="submit" variant="contained" color="primary">
            Add User
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default AddProductForm;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import customerService from './customerService';

const initialState = {
  customers: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  loggedInUser: null,
};

// Fetch users from your API
export const fetchCustomers = createAsyncThunk('customers/fetch', async (_, thunkAPI) => {
  try {
    //const token = thunkAPI.getState().auth.user.token;
    
    const customers = await customerService.fetchCustomers(); // Replace with your actual fetch function
    return customers;
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createCustomer = createAsyncThunk('customers/create', async (customerData, thunkAPI) => {
  try {
    
    return await customerService.createCustomer(customerData);
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const loginCustomer = createAsyncThunk('customers/login', async (loginData, thunkAPI) => {
  try {
    const customer = await customerService.loginCustomer(loginData);
    return customer;
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateCustomer = createAsyncThunk('customers/update', async ({ customerId, customerData }, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.customer.token;
    return await customerService.updateCustomer(customerId, customerData, token);
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteCustomer = createAsyncThunk('customers/delete', async (id, thunkAPI) => {
  try {
    
    return await customerService.deleteCustomer(id);
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.isLoading = true;
        state.isError = false; // Reset error state when fetching users
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customers = action.payload;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(loginCustomer.pending, (state) => {
        state.isLoading = true;
        state.isError = false; // Reset error state when fetching users
      })
      .addCase(loginCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.loggedInUser = action.payload;
      })
      .addCase(loginCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customers.push(action.payload);
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customers = state.customers.filter((customer) => customer._id !== action.payload._id);
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  
  },
});


export const { reset } = customerSlice.actions;
export default customerSlice.reducer;

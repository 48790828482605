import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Box, TextField, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { updateUser } from '../../../features/users/userSlice';
import '../../../pages/BackOffice/users/users.css';

const EditUserForm = ({ open, onClose, initialData , userId }) => {
  const dispatch = useDispatch();
  const userToUpdate = useSelector((state) => state.user.users.find((user) => user._id === userId)) || {};

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: '',
    active: '',
    ...initialData,
  });

  useEffect(() => {
    // Set the form data when the component mounts or when userId changes
    if (userToUpdate && Object.keys(userToUpdate).length > 0) {
      setFormData({
        firstName: userToUpdate.firstName || '',
        lastName: userToUpdate.lastName || '',
        userName: userToUpdate.userName || '',
        email: userToUpdate.email || '',
        role: userToUpdate.role || '',
        active: userToUpdate.active || '',
      });
    } 
  }, [userToUpdate, userId]);
  

  const { firstName, lastName, email, role, active, userName } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(updateUser({ userId, userData: formData }));
    onClose(); // Close the modal after submission
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'black',
          p: 4,
          width: 400,
        }}
      >
        <form onSubmit={handleSubmit}>
          <TextField className="input1" label="First Name" id="firstName" name="firstName" value={firstName} onChange={onChange} fullWidth />
          <TextField className="input1" label="Last Name" id="lastName" name="lastName" value={lastName} onChange={onChange} fullWidth />
          <TextField className="input1" label="userName" id="userName" name="userName" value={userName} onChange={onChange} fullWidth />
          <TextField className="input1" label="Email" id="email" name="email" value={email} onChange={onChange} type="email" fullWidth />
          <FormControl className="input1" fullWidth>
            <InputLabel>Role</InputLabel>
            <Select label="Role" id="role" name="role" value={role} onChange={onChange}>
              <MenuItem value="manager">Manager</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
          <FormControl className="input1" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select label="Status" id="active" name="active" value={active} onChange={onChange}>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="notActive">notActive</MenuItem>
            </Select>
          </FormControl>
          <Button type="submit" variant="contained" color="primary">
            Update User
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default EditUserForm;

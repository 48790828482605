import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, MenuItem, Select, FormControl, InputLabel, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const EditCategorieForm = ({ open, onClose, categorieId, onUpdate }) => {
 

  const [formData, setFormData] = useState({
    category_name: '',
    active: '',

  });


  useEffect(() => {
    const fetchData = async () => {
      try {
     
        // Fetch the category data by ID
        const response = await fetch(`/api/categories/${categorieId}`);
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const categorieData = await response.json();
        console.log(categorieData.category_name, categorieData.active);
        setFormData({
          category_name: categorieData.category_name,
          active: categorieData.active,
        });
      } catch (error) {
        console.error('Error fetching category data:', error.message);
      } 
    };
  
    if (open && categorieId) {
      fetchData();
    }
  }, [open, categorieId]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    

  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h6">Edit Product</Typography>
        <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
        <TextField className='input1' label="Category Name" id="category_name" name="category_name" value={formData.category_name} onChange={handleChange}  fullWidth  />
          <FormControl className='input1' fullWidth>
            <InputLabel>Status</InputLabel>
            <Select label="Status" id='active' name='active' value={formData.active} onChange={handleChange}>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="notActive">notActive</MenuItem>
            </Select>
          </FormControl>
          <Button type="submit" variant="contained" color="primary">
            Edit Categorie
          </Button>
 


        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditCategorieForm;

import axios from 'axios';

const API_URL = '/api/users';
const token = JSON.parse(localStorage.getItem('Token'))

// Create a new user
const createUser = async (userData, token) => {

  const response = await axios.post(API_URL, userData);

  return response.data;
};

// Fetch a list of users
  const fetchUsers = async () => {
    // const token = localStorage.getIt
    const Token = JSON.parse(localStorage.getItem('Token'))
    const response = await axios.get('/api/users/', {
      headers: { 'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },

    });
    
    console.log('token',Token)
    console.log('response',response.data)


  return response.data;
};

const updateUser = async (userId, userData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.put(`${API_URL}/${userId}`, userData, config);

    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteUser = async (userId, token) => {

  const response = await axios.delete(`${API_URL}/${userId}`);

  return response.data;
};

const userService = {
  createUser,
  fetchUsers,
  updateUser,
  deleteUser
};

export default userService;

import React, { useState } from 'react';

const StarRating = ({ totalStars, initialRating, onRatingChange }) => {
  const [rating, setRating] = useState(initialRating);

  const handleClick = (newRating) => {
    setRating(newRating);
    if (onRatingChange) {
      onRatingChange(newRating);
    }
  };

  return (
    <div>
      {[...Array(totalStars)].map((_, index) => (
        <span
          key={index}
          onClick={() => handleClick(index + 1)}
          style={{
            cursor: 'pointer',
            color: index < rating ? 'gold' : 'gray',
          }}
        >
          &#9733;
        </span>
      ))}
    </div>
  );
};

export default StarRating;

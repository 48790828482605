import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import categorieService from './categorieService';

const initialState = {
  categories: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

// Fetch users from your API
export const fetchCategories = createAsyncThunk('categories/fetch', async (_, thunkAPI) => {
  try {
    //const token = thunkAPI.getState().auth.user.token;
    
    const categories = await categorieService.fetchCategories();
    return categories;
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createCategorie = createAsyncThunk('categories/create', async (categorieData, thunkAPI) => {
  try {
    
    return await categorieService.createCategorie(categorieData);
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateCategorie = createAsyncThunk('categories/update', async ({ categorieId, categorieData }, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.customer.token;
    return await categorieService.updateCategorie(categorieId, categorieData, token);
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteCategorie = createAsyncThunk('categories/delete', async (id, thunkAPI) => {
  try {
    
    return await categorieService.deleteCategorie(id);
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const categorieSlice = createSlice({
  name: 'categorie',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.isLoading = true;
        state.isError = false; // Reset error state when fetching users
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createCategorie.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCategorie.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categories.push(action.payload);
      })
      .addCase(createCategorie.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteCategorie.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCategorie.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categories = state.categories.filter((categorie) => categorie._id !== action.payload._id);
      })
      .addCase(deleteCategorie.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  
  },
});

export const { reset } = categorieSlice.actions;
export default categorieSlice.reducer;

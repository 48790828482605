import React, { useState ,useEffect} from 'react';
import Slider from 'react-slick';
import './productDetails.css';
import { useParams } from 'react-router-dom';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import { styled } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Grid from '@mui/joy/Grid';
//Import tthe Redux Tools
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { addToCart } from "../../../features/Cart/cartSlice";
import Navigation from '../../../components/LandingPageComponents/global/Navigation'
import {selectProductById, fetchProductByIdAsync , selectProductListStatus} from "../../../features/products/productSlice"
import { Link } from 'react-router-dom';
import Loading from "../../../components/Loading"



import StarRating from './starRanking';
import ReviewSection from './ReviewSection'; 
const ProductDetails = () => {
  const { id } = useParams();
console.log(id)
  const dispatch = useDispatch();
  const product = useSelector(selectProductById);
  const status = useSelector(selectProductListStatus);
  const [mainImage, setMainImage] = useState('./first.png');
  const [amount, setAmount] = useState(1);
  
  
  useEffect(() => {
    dispatch(fetchProductByIdAsync(id));
  }, [dispatch, id]);
  if (status === 'loading') {
    return <Loading />;
  }
  console.log(product);


  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleThumbnailClick = (newImage) => {
    setMainImage(newImage);
  };

  const decrement = () => {
    setAmount((prev) => Math.max(1, prev - 1));
  };

  const increment = () => {
    setAmount((prev) => prev + 1);
  };

  const Item = styled(Sheet)(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.background.level1 : '#fff',
    ...theme.typography['body-sm'],
    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: 4,
    color: theme.vars.palette.text.secondary,
  }));

  return (
    <>
    <Navigation/>
      <div className='container-details'>
        <div className='productdisplay'>
          <div className='productdisplay-left'>
            <div className='productdisplay-img-list'>
              <img src={product.productImage} alt='' onClick={() => handleThumbnailClick(product.productImage)} />
              <img src={product.productImage} alt='' onClick={() => handleThumbnailClick(product.productImage)} />
              <img src={product.productImage} alt='' onClick={() => handleThumbnailClick(product.productImage)} />
              <img src={product.productImage} alt='' onClick={() => handleThumbnailClick(product.productImage)} />
            </div>
            <div className='productdisplay-img'>
              <img className='productdisplay-main-img' src={mainImage} alt='' />
            </div>
          </div>
          <div className='productdisplay-right'>
            <h1>
              {product.shortDescription}
              {product.productName}
            </h1>

            <div className='productdispaly-right-stars'>
              <StarRating totalStars={5} initialRating={4} />
              <p>(4/5)</p>
            </div>
            <div className='productdisplay-right-prices'>
              <div className='productdisplay-right-price-old'>${product.price}</div>
              <div className='productdisplay-right-price-new'>${product.price - product.price * 0.3}</div>
            </div>
            <div className='productdisplay-right-description'>
              <h1>{product.shortDescription}</h1>
            </div>
            <div className='productdisplay-right-size'>
              <h1>Select size</h1>
              <div className='productdisplay-right-size-options'>
                <div>S</div>
                <div>M</div>
                <div>L</div>
                <div>XL</div>
                <div>XXL</div>
              </div>
            </div>
            <div className='productdisplay-right-addtocart'>
              <div className='buttons-moins-plus' >
              <button className='button-moins' onClick={decrement}>-</button>
              <span>{amount}</span>
              <button className='button-plus' onClick={increment}>+</button>
              </div>
              <button className='add-to-cart-button'>ADD TO CART</button>
            
            </div>
          </div>
        </div>


        <Tabs className='details-tabs' aria-label="Basic tabs" defaultValue={0}>
          <TabList className='tablist-des'>
            <Tab className='tab1'>Reviews & Rating</Tab>
            <Tab className='tab2'>Description</Tab>
            <Tab className='tab3'>FAQ's</Tab>
          </TabList>

          <TabPanel value={0}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              {[...Array(6)].map((_, index) => (
                <Grid key={index} item xs={6}>
                  <Item className='item1'>
                    <ReviewSection />
                  </Item>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          
          <TabPanel value={1}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}   className='grid-jus' >
              {[...Array(1)].map((_, index) => (
                <Grid key={index} item xs={12} md={8}>
                  <Item className='item2'>
                    <div className='productdisplay-right-descriptions'>
                      <h1>Jacket Description</h1>
                      <ul>
                        <li><strong>Couleur:</strong> Multicolore</li>
                        <li><strong>Style:</strong> Casual</li>
                        <li><strong>Type de motif:</strong> Blocs de couleur</li>
                        <li><strong>Détails:</strong> Cordon, Poche, Fermeture éclair</li>
                        <li><strong>Type:</strong> Zippé</li>
                        <li><strong>Type du col:</strong> Capuche</li>
                        <li><strong>Longueur des manches:</strong> Manches longues</li>
                        <li><strong>Type de manches:</strong> Épaules Tombantes</li>
                        <li><strong>Longueur:</strong> Classique</li>
                        <li><strong>Type d'ajustement:</strong> Large</li>
                        <li><strong>Tissu:</strong> Extensibilité légère</li>
                        <li><strong>Tissu/matériel:</strong> Tissu tricoté</li>
                        <li><strong>Composition:</strong> 100% Polyester</li>
                        <li><strong>Instructions d'entretien:</strong> Lavage en machine ou nettoyage à sec professionnel</li>
                        <li><strong>Température:</strong> Printemps/Automne (18-25℃/63-77℉)</li>
                        <li><strong>Transparent:</strong> Non</li>
                      </ul>
                    </div>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </TabPanel>

          <TabPanel value={2}>
            
          </TabPanel>
        </Tabs>
        {/* <footershs />sds */}
      </div>
    </>
  );
};

export default ProductDetails;
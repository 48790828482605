// import {  Routes, Route } from 'react-router-dom';
import { useState } from "react";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../../components/BackOfficeComponents/global/theme";
import Sidebar from "../../../components/BackOfficeComponents/global/Sidebar";
import Topbar from "../../../components/BackOfficeComponents/global/Topbar";
import StatBox from "../../../components/BackOfficeComponents/dashboardComponents/StateBox";
import {Button, Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from  "../../../components/BackOfficeComponents/global/theme"
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import LineChart from "../../../components/BackOfficeComponents/dashboardComponents/LineChart";
import PieChart from "../../../components/BackOfficeComponents/dashboardComponents/PieChart";
import ProgressCircle from "../../../components/BackOfficeComponents/dashboardComponents/ProgressCircle";
import BarChart from "../../../components/BackOfficeComponents/dashboardComponents/BarChart";
import { mockTransactions as Transactions } from "../../../components/BackOfficeComponents/global/mockData";
import './Dashboard.css'

function Dashboard2() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const colors = tokens(theme.palette.mode);
  

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app2">
        <Sidebar isSidebar={isSidebar} />
          <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
{/* Grid and then put chart's here  */}
{/* Bar chart Grid  */}
<Box m="20px">
      {/*-------------------------------------------- HEADER ---------------------------------------------------*/}
      <Box className="Header" >
        <Box>
          <Button className= "export-btn" sx={{ backgroundColor: colors.blueAccent[700], color: colors.grey[100] }} >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box>

      {/*------------------------------------------------ GRID & CHARTS -----------------------------------------*/}
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="140px" gap="20px" >
        {/* ROW 1 */}
        <Box className="row1" gridColumn="span 3" backgroundColor={colors.primary[400]} >
            <StatBox className="card1" title="12,361" subtitle="Emails Sent" progress="0.75" increase="+14%"
              icon={ <EmailIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} /> } 
            />
        </Box>
        <Box className="row1" gridColumn="span 3" backgroundColor={colors.primary[400]} >
            <StatBox title="431,225" subtitle="Sales Obtained" progress="0.50" increase="+21%"
              icon={ <PointOfSaleIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }}/> }
            />
        </Box>
        <Box className="row1" gridColumn="span 3" backgroundColor={colors.primary[400]}>
            <StatBox title="32,441" subtitle="New Clients" progress="0.30" increase="+5%"
              icon={ <PersonAddIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }}/> }
            />
        </Box>
        <Box className="row1" gridColumn="span 3" backgroundColor={colors.primary[400]}>
            <StatBox title="1,325,134" subtitle="Traffic Received" progress="0.80" increase="+43%"
              icon={ <TrafficIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />}
            />
        </Box>

        {/* ROW 2 */}
        <Box className="r2" gridColumn="span 8" gridRow="span 2" backgroundColor={colors.primary[400]} >
          <Box className="row2">
            <Box>
              <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                Revenue Generated
              </Typography>
              <Typography variant="h3" fontWeight="bold" color={colors.greenAccent[500]}>
                $59,342.32
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Transactions
            </Typography>
          </Box>
          {Transactions && Transactions.map((transaction, i)  => (
            <Box key={`${transaction.txId}-${i}`} display="flex" justifyContent="space-between" alignItems="center" borderBottom={`4px solid ${colors.primary[500]}`}
         p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                ${transaction.cost}
              </Box>
            </Box>
          ))}
        </Box>

        {/* ROW 3 */}
        <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.primary[400]} borderRadius="25px" p="30px">
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center" mt="25px" >
            <ProgressCircle size="125" />
            <Typography variant="h5" color={colors.greenAccent[500]} sx={{ mt: "15px" }} >
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box>
        <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.primary[400]} borderRadius="25px" >
          <Typography variant="h5" fontWeight="600" sx={{ padding: "30px 30px 0 30px" }} >
            Sales Quantity
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Box>
        <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.primary[400]} borderRadius="25px" p="30px">
          <Typography variant="h5" fontWeight="600">
            Campaign
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center" mt="25px" >
            <ProgressCircle size="125" />
            <Typography variant="h5" color={colors.greenAccent[500]} sx={{ mt: "15px" }} >
              $48,352 revenue generated
            </Typography>
            <Typography>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box>
      </Box>
    </Box>

              </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default Dashboard2;




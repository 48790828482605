import React, { useReducer, useState } from 'react';
import { CssBaseline, ThemeProvider, TextField, Button } from "@mui/material";
import Avatar from '@mui/material/Avatar';

import { ColorModeContext, useMode } from "../../../components/BackOfficeComponents/global/theme";
import Sidebar from "../../../components/BackOfficeComponents/global/Sidebar";
import Topbar from "../../../components/BackOfficeComponents/global/Topbar";
import { Box, Typography } from "@mui/material";
import './userProfile.css';

const Profile = () => {
  const ur = JSON.parse(localStorage.getItem('user'))
  const createdOn = new Date(ur.creationDate).toLocaleString();
  const lastLogin = new Date(ur.creationDate).toLocaleString();
  const [user, setUser] = useState({
    name: ur.firstName,
    email: ur.email,
    username: ur.userName,
    role: ur.role,
    createdOn: createdOn,
    lastLogin: lastLogin,
  });

  const [updateUser, setUpdateUser] = useState({
    name: '',
    email: '',
    username: '',
    role: '',
    createdOn: '',
    lastLogin: '',
  });

  const [password, setPassword] = useState('');

  const handleChange = (field, target) => {
    const newValue = target.value;
    setUser((prevUser) => ({ ...prevUser, [field]: newValue }));
    setUpdateUser((prevUpdateUser) => ({ ...prevUpdateUser, [field]: newValue }));
  };

  const handleChangePassword = () => {
    // Implement change password logic
    console.log("Password changed:", password);
  };

  const handleUpdate = () => {
    // Implement update logic
    console.log("User data updated:", updateUser);
  };
  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  } 

  return (
    
    <Box className='body-one' >
        <Box className='ava-img'>
        <Avatar  {...stringAvatar(`${ur.firstName} ${ur.lastName}`)}  />
        </Box>
      <Typography variant="h5" gutterBottom>
        User Profile
      </Typography>
      <TextField
        label="Name"
        value={user.name}
        onChange={(e) => handleChange('name', e.target)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        value={user.email}
        onChange={(e) => handleChange('email', e.target)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Username"
        value={user.username}
        onChange={(e) => handleChange('username', e.target)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Role"
        value={user.role}
        onChange={(e) => handleChange('role', e.target)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Created On"
        value={user.createdOn}
        disabled
        onChange={(e) => handleChange('createdOn', e.target)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Last Login"
        value={user.lastLogin}
        onChange={(e) => handleChange('lastLogin', e.target)}
        fullWidth
        margin="normal"
      />

      {/* Update Button */}
      <Button variant="contained" color="primary" onClick={handleUpdate}>
        Update
      </Button>

      {/* Change Password Section */}
      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Change Password
      </Typography>
      <TextField
        type="password"
        label="New Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleChangePassword}>
        Change Password
      </Button>
    </Box>
  );
};

const UserProfile = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app2">
          <Sidebar isSidebar={isSidebar} />
          <main className="content2">
            <Topbar setIsSidebar={setIsSidebar} />
            <Profile />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default UserProfile;

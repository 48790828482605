import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import './Navigation.css';
import { AiOutlineUserAdd } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

const Navigation = () => {

  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

   {/** gsap.fromTo(
      '.nav2',
      {
        x: 50,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 2,
        delay: 1,
      }
    ); */}
  }, []); // Empty dependency array to ensure it runs only once when the component mounts

  return (
    <div className="nav2">
      <div className="links">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/shop">Shop</Link></li>
          <li><Link to="/About">About&nbsp;Us</Link></li>
          <li>
            <div className="dropdown">
              <button className="dropbtn">Categories</button>
              <div className="dropdown-content">
                <Link to="/man">Men</Link>
                <Link to="/Women">Women</Link>
                <Link to="/Kids">Kids</Link>
              </div>
            </div>
          </li>
          <li><Link to="/hello">Contact&nbsp;Us</Link></li>
        </ul>
      </div>
      <div className="links2">
        <ul>
          <li><Link to="/registerc"><AiOutlineUserAdd /></Link></li>
          <li><Link to="/Cart"><FaShoppingCart /></Link></li>
        </ul>
      </div>
    </div>
  );
};

export default Navigation;

import { createSlice } from "@reduxjs/toolkit";
import { productsApi } from "./productsApi";
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
const API_URL = '/api/products';
const initialState = {
  items: [],
  status: null,
};

export const productsFetch = createAsyncThunk(
  "products/productsFetch",
  async () => {
    try {
      const response = await axios.get('');
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(productsApi.endpoints.getAllProducts.matchPending, (state) => {
        state.status = "pending";
      })
      .addMatcher(productsApi.endpoints.getAllProducts.matchFulfilled, (state, action) => {
        state.items = action.payload;
        state.status = "success";
      })
      .addMatcher(productsApi.endpoints.getAllProducts.matchRejected, (state, action) => {
        state.status = "rejected";
      });
  },
});

export default productsSlice.reducer;



// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
// import { productsApi } from "./productsApi";
// // const API_URL = '/api/products';


// const initialState = {
//   items: [],
//   status: null,
// };

// export const productsFetch = createAsyncThunk('products/productsFetch', async (_, thunkAPI) => {
//   try {
//     const products = await productsApi(); // Replace with your actual fetch function
//     // console.log(products.data);

//     return products;
//   } catch (error) {
//     const message =
//       (error.response &&
//         error.response.data &&
//         error.response.data.message) ||
//       error.message ||
//       error.toString();
//     return thunkAPI.rejectWithValue(message);
//   }
// });
// // export const productsFetch = createAsyncThunk(
// //   "products/productsFetch",
// //   async () => {
// //     try {
// //       const response = await axios.get(
// //         "/products"
// //       );
// //       console.log(response.data);

// //       return response.data;
// //     } catch (error) {
// //       console.log(error);
// //     }
// //   }
// // );

// const productsSlice = createSlice({
//   name: "products",
//   initialState,
//   reducers: {},
//   extraReducers: {
//     [productsFetch.pending]: (state, action) => {
//       state.status = "pending";
//     },
//     [productsFetch.fulfilled]: (state, action) => {
//       state.items = action.payload;
//       state.status = "success";
//     },
//     [productsFetch.rejected]: (state, action) => {
//       state.status = "rejected";
//     },
//   },
// });

// export default productsSlice.reducer;
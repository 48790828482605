import React, { useRef,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { addToCart } from "../../Cart/cartSlice";
import { fetchBrandsAsync, fetchCategoriesAsync, fetchProductsByFiltersAsync,
         selectAllProducts, selectSubCategories, selectCategories,
         fetchSubCategoriesAsync, fetchProducts, selectTotalItems,  selectProductListStatus,} from '../productSlice';
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon, StarIcon,} from '@heroicons/react/20/solid';
import { ITEMS_PER_PAGE } from '../../commun/constants';
import Pagination from '../../commun/Pagination';
import { Grid } from 'react-loader-spinner';


 function ProductList () {
  const dispatch = useDispatch();
  const products = useSelector(selectAllProducts)
  const subCategories = useSelector(selectSubCategories);
  const categories = useSelector(selectCategories)
  const totalItems = useSelector(selectTotalItems);
  const status = useSelector(  selectProductListStatus,);

  // const { items: products, status } = useSelector((state) => state.products);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]); 

  useEffect(() => {
    dispatch(fetchSubCategoriesAsync());
    dispatch(fetchCategoriesAsync());
  }, []);

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };


  if (status === 'error') {
    return <p>Error fetching products</p>;
  }

  function ProductGrid() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-2xl px-4 py-0 sm:px-6 sm:py-0 lg:max-w-7xl lg:px-8">
          <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
            {status === 'loading' ? (
              <div>Loading...</div>
            ) : null}
            {products.map((product) => (
              <div key={product._id} className="group relative ">
                <Link to={`/productDetails/${product._id}`}>
                  <div className="min-h-60 aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-60">
                    <img
                      src={product.productImage}
                      alt={product.productName}
                      className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                    />
                  </div>
                  <div className="mt-4 flex justify-between">
                    <div>
                      <h3 className="text-sm text-gray-700">{product.productName}</h3>
                      <p className="mt-1 text-sm text-gray-500">
                        <StarIcon className="w-6 h-6 inline" />
                        <span className="align-bottom">{product.rating}</span>
                      </p>
                    </div>
                    <div>
                      <p className="text-sm block font-medium text-white-900">${product.discountPrice}</p>
                      <p className="text-sm block line-through font-medium text-gray-400">${product.price}</p>
                    </div>
                  </div>
                </Link>
                <Link to="/cart" className="block mt-2">
                  <button
                    className="px-6 py-2 transition ease-in duration-200 uppercase rounded-full hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
                    onClick={() => handleAddToCart(product)}
                  >
                    Add to Cart
                  </button>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-Black">
      <div>
        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <section aria-labelledby="products-heading" className="pb-24 pt-6">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Product grid */}
              <div className="lg:col-span-3 ">
                <ProductGrid></ProductGrid>
              </div>
              {/* Product grid end */}
            </div>
          </section>
        </main>
      </div>
  </div>
  )
}

export default ProductList;
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navigation from '../../components/LandingPageComponents/global/Navigation'
import { addToCart, clearCart, decreaseCart, getTotals, removeFromCart,} from "./cartSlice";
import { Card, CardHeader, CardBody, CardFooter, Typography,  Dialog, DialogHeader, DialogBody,
  DialogFooter, Avatar, IconButton, Button, Input } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import './cart.css'


const Cart = () => {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };
  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
  };
  const handleClearCart = () => {
    dispatch(clearCart());
  };
  return (
    
    <>
    
    <div className="bassket">
   
      <div className="cart-container">
          <h2>Shopping Cart</h2>
          {cart.cartItems.length === 0 ? (
            <Card shadow={false} className="relative grid h-[39rem] w-full max-w-[40rem] items-end justify-center overflow-hidden text-center margin" >
                <Link to={"/product"} className="p-link">
                            <Typography variant="h5" className="" color="white"> Back to Store </Typography>
                          </Link>
              <CardHeader floated={false} shadow={false} color="transparent"
                           className="absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://images.unsplash.com/photo-1483193722442-5422d99849bc?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8c29ycnl8ZW58MHx8MHx8fDA%3D')] bg-cover bg-center">
                           <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
                        
               </CardHeader>
              <CardBody className="relative ">
                           <Typography variant="h2" color="white" className="mb-6 font-medium leading-[1.5]">
                             Your Cart is Empty.
                             Explore our products and find something you like!
                           </Typography>
                           <Typography variant="h5" className="mb-4 text-gray-400">
                             Iver
                           </Typography>
              </CardBody>
            </Card>
           
          ) : (
            <div>
              <div className="titles">
                <h3 className="product-title">Product</h3>
                <h3 className="price">Price</h3>
                <h3 className="quantity">Quantity</h3>
                <h3 className="total">Total</h3>
              </div>
              <div className="cart-items">
                {cart.cartItems &&
                  cart.cartItems.map((cartItem) => (
                    <div className="cart-item" key={cartItem._id}>
                      <div className="cart-product">
                        <img src={cartItem.productImage} alt={cartItem.productName} />
                        <div>
                          <h3>{cartItem.productName}</h3>
                          <p>{cartItem.shortDescription}</p>
                          <button onClick={() => handleRemoveFromCart(cartItem)}>
                            Remove
                          </button>
                        </div>
                      </div>
                      <div className="cart-product-price">${cartItem.price}</div>
                      <div className="cart-product-quantity">
                        <button onClick={() => handleDecreaseCart(cartItem)}>
                          -
                        </button>
                        <div className="count">{cartItem.cartQuantity}</div>
                        <button onClick={() => handleAddToCart(cartItem)}>+</button>
                      </div>
                      <div className="cart-product-total-price">
                        ${cartItem.price * cartItem.cartQuantity}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="cart-summary">
                <button className="clear-btn" onClick={() => handleClearCart()}>
                  Clear Cart
                </button>
                <div className="cart-checkout">
                  <div className="subtotal">
                    <span>Subtotal</span>
                    <span className="amount">${cart.cartTotalAmount}</span>
                  </div>
                  <p>Taxes and shipping calculated at checkout</p>
                  <Link to="/check">
                <button
                  className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 ml-4 rounded"
                >
                  Checkout
                </button>
              </Link>
                  <div className="continue-shopping">
                    <Link to="/">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-arrow-left"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                        />
                      </svg>
                      <span>Continue Shopping</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>

    </>
    
  );
};

export default Cart;
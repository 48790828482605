import Navigation from "../../../components/LandingPageComponents/global/Navigation";
import ProductList from "../../../features/products/components/ProductList";
import React from 'react';

const Product = () => {
  const containerStyle = {
    background: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };

  return (
    <div style={containerStyle}>
      <Navigation />
      <ProductList />
    </div>
  );
};

export default Product;

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createCustomer, reset } from '../../../features/customers/customerSlice';
import logo from './logo.png'
import './register.css';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import  Navigation from '../../../components/LandingPageComponents/global/Navigation'

function Register() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });

  const { first_name, last_name, email, password } = formData;

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isError) {
      console.log(message);
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(createCustomer(formData));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="body-me">
          <Navigation />
      <ThemeProvider theme={createTheme()}>
        <Container className="regi-home" component="main" maxWidth="xs">
          <CssBaseline />
          <Box  sx={{ marginTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '27px' }}>
            <Avatar className="avatar-des" alt="Remy Sharp" src={logo} size="56" />
            <Typography className='create-acc' component="h1" variant="h5">
              Create Account
            </Typography>
            <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 3, }}>
              <Grid  container spacing={2}>
                <Grid item xs={10} sm={46}>
                  <TextField
                
                    autoComplete="given-name"
                    name="first_name"
                    required
                    fullWidth
                    id="first_name"
                    label="First Name"
                    value={first_name}
                    autoFocus
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={10} sm={46}>
                  <TextField
                    required
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    name="last_name"
                    placeholder="Type your Last Name here…"
                    variant="outlined"
                    value={last_name}
                    autoComplete="family-name"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={email}
                    autoComplete="email"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    value={password}
                    autoComplete="new-password"
                    onChange={onChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            sx={{
                              color: showPassword ? '#b51f1f' : 'rgba(69, 69, 68, 0.54)'}}// Change color based on visibility
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Button className='signup-btn' type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Sign Up
              </Button>
              <Grid className="al-ac" container justifyContent="flex-end">
                <Grid item>
                  <Link className="alreday-acc" href="/Sign in" variant="body2">
                    Already have an account? Sign&nbsp;in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default Register;
import React , {useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Box, Typography, Button} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid'
import moment from 'moment';
import { fetchUsers, deleteUser ,selectLoggedInUser} from '../../../features/users/userSlice'; // Replace with the correct path to your Redux slice and action
import './Tables.css'
import { useTheme } from '@mui/material/styles';
import UserActions from './usersActions'
import EditUserForm from './EditUserForm'
import UpdateUserForm from './UpdateUserForm'
import { Navigate } from 'react-router-dom';
import { user  } from '../../../features/auth/authSlice';
import {updateUser} from '../../../features/users/userSlice'
import userService from '../../../features/users/userService'
// import { selectLoggedInUser } from '../authSlice';

import NotAuthorizedComponent from "../../NotAuthorizedComponent"


const Users = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const selectUser = useSelector(user);



  // Access user data and loading/error states from the Redux store
  const users = useSelector((state) => state.user.users);
  const isLoading = useSelector((state) => state.user.isLoading);
  const isError = useSelector((state) => state.user.isError);

  

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const [editFormData, setEditFormData] = useState(null);

  // Callback function for handling edit action
  const handleAddClick = (userId) => {
    setEditModalOpen(true);
    setSelectedUserId(userId);
  };

  const handleDeleteClick = (userId) => {
    dispatch(deleteUser(userId));
  };

  const handleSaveClick = async () => {

    try {
      if (!selectedUserId) {
        console.error('Invalid user ID');
        return;
      }
  
      dispatch(updateUser({ userId: selectedUserId, userData: editedData }));

      await userService.updateUser(selectedUserId, editedData);

      setEditModalOpen(false);
    } catch (error) {
      console.error('Error updating user data:', error);

    }
  };

  // Dispatch the fetchUsers action when the component mounts
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchUsers());
      } catch (error) {
        console.error('Error fetching users:', error);
        // Handle the error gracefully, e.g., show a message to the user
      }
    };
  
    fetchData();
  }, [dispatch]);


  const handleEditClick = (userId) => {
    setEditModalOpen(true);
    setSelectedUserId(userId);

    const selectedUser = users.find((user) => user._id === userId);

    console.log('Selected User:', selectedUser);

    if (selectedUser) {
      setEditFormData({
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        userName: selectedUser.userName,
        email: selectedUser.email,
        role: selectedUser.role,
        active: selectedUser.active,
      });
    } else {
      console.warn(`User with ID ${userId} not found.`);
    }
  };

  const [pageSize, setPageSize] = useState(5)
  const [rowId, setRowId] = useState(null)
  const data = users 

  const columns = useMemo(
    () => [
      {
        field: 'firstName',
        headerName: 'First Name ',
        width: 100,
        editable: true,
      },
      { field: 'lastName', headerName: 'Last Name', width: 100, editable: true, },
      { field: 'userName', headerName: 'userName', width: 100, editable: true, },
      { field: 'email', headerName: 'Email', width: 170, editable: true, },
      {
        field: 'role',
        headerName: 'Role',
        width: 100,
        type: 'singleSelect',
        valueOptions: ['manager', 'admin'],
        editable: true,
      },
      {
        field: 'active',
        headerName: 'Active',
        width: 50,
        type: 'boolean',
        editable: true,
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        width: 150,
        renderCell: (params) =>
          moment(params.row.createdAt).format('YYYY-MM-DD HH:MM:SS'),
      },
      { field: '_id', headerName: 'Id', width: 220 },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 150,
        renderCell: (params) => (
          <UserActions {...{params, rowId, setRowId}} onEditClick={() => handleEditClick(params.row._id)} onDeleteClick={() => handleDeleteClick(params.row._id)} />
        ),
      },
    ],
    [rowId]
  );


  return (
    <Box sx={{height: 400, width: '95%', marginLeft: 4}}>
        <Typography variant='h3' component='h3' sx={{textAlign:'center', mt:3, mb:3}}>
            Manage Users
        </Typography>
        <Button
        variant="contained"
        color="primary"
        onClick={handleAddClick} 
        sx={{ marginBottom: 2 }}
      >
        Create
      </Button>
      <Button variant="contained" color="primary" onClick={handleSaveClick} sx={{ marginBottom: 2 }}>
  Save
</Button>
        <DataGrid
            columns={columns}
            rows={data}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize = {pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            getRowSpacing={params=>({
              top:params.isFirstVisible ? 0 : 5,
              bottom:params.isLastVisible ? 0 : 5
            })}
            data-mui-light={theme.palette.mode === 'light'}
              getRowClassName={(params) => {
                return theme.palette.mode === 'light' ? 'light-theme-row' : 'dark-theme-row';
            }}
            onCellEditCommit={(params) => {
              setRowId(params.id)

            }}
            
            
        />
         <UpdateUserForm open={isEditModalOpen} onClose={() => setEditModalOpen(false)} initialData={editFormData}/>
         <EditUserForm open={isEditModalOpen} onClose={() => setEditModalOpen(false)} />
    </Box>
  ); 
};

export default Users;


 
 export default function Loading() {
    return (
  
 
  <button class="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 w-10 animate-spin">
   <svg
     xmlns="http://www.w3.org/2000/svg"
     width="24"
     height="24"
     viewBox="0 0 24 24"
     fill="none"
     stroke="currentColor"
     stroke-width="2"
     stroke-linecap="round"
     stroke-linejoin="round"
     class="h-4 w-4"
   >
     <line x1="12" x2="12" y1="2" y2="6"></line>
     <line x1="12" x2="12" y1="18" y2="22"></line>
     <line x1="4.93" x2="7.76" y1="4.93" y2="7.76"></line>
     <line x1="16.24" x2="19.07" y1="16.24" y2="19.07"></line>
     <line x1="2" x2="6" y1="12" y2="12"></line>
     <line x1="18" x2="22" y1="12" y2="12"></line>
     <line x1="4.93" x2="7.76" y1="19.07" y2="16.24"></line>
     <line x1="16.24" x2="19.07" y1="7.76" y2="4.93"></line>
   </svg>
   <span class="sr-only">Loading...</span>
 </button>
    )}
 

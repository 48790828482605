import React, {useEffect} from 'react'
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import './man.css'
import Navigation from '../../../components/LandingPageComponents/global/Navigation'
import Footer from '../../../components/LandingPageComponents/global/footer';

import image1 from './man-fashion.jpg'
import winter from './img/winter.jpg'
import winterman from './img/winter-man.png'
import summer from './img/summer.jpg'
import autumn from './img/autumn.jpg'
import spring from './img/spring.jpg'
import summerman from './img/summer-man.png'
import autumnman from './img/autumn-man.png'
import springman from './img/spring-man.png'

function Man() {

 
  useEffect(() => {
    // GSAP animation for loading-page
    gsap.fromTo(
      '.loading-page',
      { opacity: 1 },
      {
        opacity: 0,
        display: 'none',
        duration: 1.5,
        delay: 3.5,
      }
    );

    // GSAP animation for logo-name
    gsap.fromTo(
      '.logo-name',
      {
        y: 50,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 2,
        delay: 0.5,
      }
    );

    gsap.registerPlugin(ScrollTrigger)

    // GSAP animation for winter title
    gsap.fromTo(
          '.winter',
          {
            y: 50,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            duration: 2,
            delay: 0.5,
            scrollTrigger: {
              trigger: '.winter',
              start: 'top 80%',
              end: 'bottom 20%',
              scrub: 1
              
            },
          }
    );
    
    // GSAP animation for slog
    gsap.fromTo(
      '.slog',
      {
        y: 50,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 2,
        delay: 0.5,
        scrollTrigger: {
          trigger: '.slog',
          start: 'top 80%',
          end: 'bottom 20%',
          scrub: 1
          
        },
      }
    );

    // GSAP animation for Model
    gsap.fromTo(
      '.model',
      {
        x: 50,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 2,
        delay: 0.5,
        scrollTrigger: {
          trigger: '.model',
          start: 'top 80%',
          end: 'bottom 20%',
          scrub: 1
          
        },
      }
    );
    
    // GSAP animation for description
    gsap.fromTo(
      '.description',
      {
        y: 50,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 2,
        delay: 0.5,
        scrollTrigger: {
          trigger: '.description',
          start: '-500% 80%',
          end: '-100% bottom',
        
          scrub: 1
          
        },
      }
    );


  }, []);



  return (
    <>

      <section className="first-section">

          <div className="bg">
            <img src={image1}></img>
          </div>

          <div className="contenu">
            <span className="title">Iver</span><br></br>
            <span className="sub-title">For our Chaps</span>
          </div>
          
        </section>

        <section className="winterClothes">
          <div className="bg2">
            <img className='w-img' src={winter} alt="" />
            <div className='winter-content winter'>
              <Link to="/landing">Winter</Link>
            </div>
            <div className="model" >
              <img  src={winterman} alt="" />
            </div>
            <div className="description" >
              Complete your winter wardrobe with our versatile collection of scarves, hats, and gloves. Made from soft and insulating fabrics, these accessories not only keep you warm but also add a touch of flair to your ensemble
            </div>
            <div className="slog">
              Elevate Winter Elegance, Warmly.
            </div>
          </div>
      </section>

      <section className="winterClothes">
          <div className="bg2">
            <img className='w-img' src={summer} alt="" />
            <div className='summer-content summer'>
              summer
            </div>
            <div className="model" >
              <img  src={summerman} alt="" />
            </div>
            <div className="s-description" >
              Complete your winter wardrobe with our versatile collection of scarves, hats, and gloves. Made from soft and insulating fabrics, these accessories not only keep you warm but also add a touch of flair to your ensemble
            </div>
            <div className="slog">
              Embrace the Heat with Our Summerwear Beat!
            </div>
          </div>
      </section>

      <section className="winterClothes">
          <div className="bg2">
            <img className='w-img' src={autumn} alt="" />
            <div className='summer-content summer'>
              autumn
            </div>
            <div className="model" >
              <img  src={autumnman} alt="" />
            </div>
            <div className="description" >
              Complete your winter wardrobe with our versatile collection of scarves, hats, and gloves. Made from soft and insulating fabrics, these accessories not only keep you warm but also add a touch of flair to your ensemble
            </div>
            <div className="slog">
              Fall into Fashion: Embrace the Cozy Elegance of Autumn with
            </div>
          </div>
      </section>

      <section className="winterClothes">
          <div className="bg2">
            <img className='w-img' src={spring} alt="" />
            <div className='summer-content summer'>
              spring
            </div>
            <div className="model" >
              <img  src={springman} alt="" />
            </div>
            <div className="description" >
              Complete your winter wardrobe with our versatile collection of scarves, hats, and gloves. Made from soft and insulating fabrics, these accessories not only keep you warm but also add a touch of flair to your ensemble
            </div>
            <div className="slog">
              Embrace the Heat with Our Summerwear Beat!
            </div>
          </div>
      </section>

 
    </>
  );
}

export default Man

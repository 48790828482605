import axios from 'axios';
import { useEffect, useState } from 'react';
import { getUsername } from '../helper/helper';

axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN;

/** Custom hook */
export default function useFetch(query) {
  const [getData, setData] = useState({
    isLoading: false,
    apiData: undefined,
    status: null,
    serverError: null,
  });

  useEffect(() => {
    let isMounted = true; // Flag to check if the component is mounted

    const fetchData = async () => {
      try {
        setData((prev) => ({ ...prev, isLoading: true }));

        const { username } = !query ? await getUsername() : { username: undefined };

        const { data, status } = !query
          ? await axios.get(`/api/Rcustomer/user/${username}`)
          : await axios.get(`/api/Rcustomer/${query}`);

        if (isMounted) {
          setData((prev) => ({ ...prev, apiData: data, status: status, isLoading: false }));
        }
      } catch (error) {
        console.error('Error during data fetching:', error);

        if (isMounted) {
          setData((prev) => ({ ...prev, isLoading: false, serverError: error }));
        }
      }
    };

    fetchData();

    // Cleanup: Set the flag to false when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, [query]);

  return [getData, setData];
}

import React , {useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Box, Typography, Button} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid'
import moment from 'moment';
import { fetchOrders, deleteOrder } from '../../../features/orders/orderSlice'; // Replace with the correct path to your Redux slice and action
//import './Tables.css'
import { useTheme } from '@mui/material/styles';
import AddOrderForm from './AddOrderForm'
import OrderActions from './orderActions';



const Orders = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  // Access user data and loading/error states from the Redux store
  const orders = useSelector((state) => state.order.orders);
  const isLoading = useSelector((state) => state.order.isLoading);
  const isError = useSelector((state) => state.user.isError);
 
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);


  const handleAddClick = () => {
    setAddModalOpen(true);
    setSelectedOrderId(null);
  };

  const handleDeleteClick = async (corderId) => {
    try{
      await dispatch(deleteOrder(corderId));

      setDeleteSuccessMessage('Order deleted successfully');

      await dispatch(fetchOrders());
    } catch (error) {
      console.error('Error deleting Order:', error.message);
    }

  };

  const handleDismissMessage = () => {
    setDeleteSuccessMessage(null);
  };

  // Dispatch the fetchUsers action when the component mounts
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchOrders());
      } catch (error) {
        console.error('Error fetching orders:', error);
        // Handle the error gracefully, e.g., show a message to the user
      }
    };
  
    fetchData();
  }, [dispatch]);

  const [pageSize, setPageSize] = useState(5)
  const [rowId, setRowId] = useState(null)
  const data = orders 

  const columns = useMemo(
    () => [
      {
        field: 'customerId',
        headerName: 'customerId',
        width: 100,
      },
      {
        field: 'orderItems',
        headerName: 'Order Items',
        width: 200,
        renderCell: (params) => (
          <div>
            {params.row.orderItems.map((item, index) => (
              <div key={index}>
                Product: {item.product}, Quantity: {item.quantity}
              </div>
            ))}
          </div>
        ),
      },
      { field: 'orderDate', headerName: 'orderDate', width: 100 },
      { field: 'cartTotalPrice', headerName: 'cartTotalPrice', width: 170 },
      {
        field: 'status',
        headerName: 'status',
        width: 100,
        type: 'singleSelect',
        valueOptions: ['Open', 'Shipped', 'Paid', 'Closed', 'Canceled'],
        editable: true,
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        width: 200,
        renderCell: (params) =>
          moment(params.row.createdAt).format('YYYY-MM-DD HH:MM:SS'),
      },
      { field: '_id', headerName: 'Id', width: 220 },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 200,
        renderCell: (params) => (
          <OrderActions
            onAddClick={() => handleAddClick()}
            onDeleteClick={() => handleDeleteClick(params.row._id)}
          />
        ),
      }
      
    ],
    [rowId]
  );

  return (
    <Box sx={{ height: 400, width: '95%', marginLeft: 4 }}>
    <Typography variant='h3' component='h3' sx={{ textAlign: 'center', mt: 3, mb: 3 }}>
      Manage Orders
    </Typography>

    {deleteSuccessMessage && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <Typography variant="body1" color="success">
            {deleteSuccessMessage}
          </Typography>
          <Button onClick={handleDismissMessage} color="inherit">
            Dismiss
          </Button>
        </Box>
      )}
      
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddClick} 
          sx={{ marginBottom: 2 }}
        >
          Create
        </Button>
      <DataGrid
        columns={columns}
        rows={data}
        getRowId={(row) => row._id}
        rowsPerPageOptions={[5, 10, 20]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        getRowSpacing={(params) => ({
          top: params.isFirstVisible ? 0 : 5,
          bottom: params.isLastVisible ? 0 : 5,
        })}
        data-mui-light={theme.palette.mode === 'light'}
        getRowClassName={(params) => {
          return theme.palette.mode === 'light' ? 'light-theme-row' : 'dark-theme-row';
        }}
        onCellEditCommit={(params) => {
          console.log('Cell edited:', params);
          setRowId(params.id);
        }}
      />
    <AddOrderForm open={isAddModalOpen} onClose={() => setAddModalOpen(false)} />
  </Box>
  ); 
};

export default Orders;

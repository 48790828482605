import React from 'react';
import { Box, Typography } from '@mui/material';
import LockPersonIcon from '@mui/icons-material/LockPerson';

const NotAuthorizedComponent = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      <LockPersonIcon sx={{ fontSize: 80, color: 'error.main' }} />
      <Typography variant="h5" color="error" mt={2}>
        You are not authorized to view this content.
      </Typography>
      <Typography variant="body1" color="textSecondary" mt={1}>
        Please log in with the appropriate credentials.
      </Typography>
      {/* You can add additional UI elements or links to a login page */}
    </Box>
  );
};

export default NotAuthorizedComponent;
import React , {useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Box, Typography, Button} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid'
import moment from 'moment';
import { fetchCustomers, deleteCustomer } from '../../../features/customers/customerSlice'; 
//import './Tables.css'
import { useTheme } from '@mui/material/styles';
import CustomerActions from './CustomerActions'
import EditCustomerForm from './editCustomerForm'


const Customers = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  // Access user data and loading/error states from the Redux store
  const customers = useSelector((state) => state.customer.customers);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);


  const handleAddClick = () => {
    setEditModalOpen(true);
    setSelectedCustomerId(null);
  };
  
  const handleDeleteClick = async (customerId) => {
    
    try{
      await dispatch(deleteCustomer(customerId));

      setDeleteSuccessMessage('Customer deleted successfully');

      await dispatch(fetchCustomers());
    } catch (error) {
      console.error('Error deleting Customer:', error.message);
    }
  };

  
  const handleDismissMessage = () => {
    setDeleteSuccessMessage(null);
  };

  // Dispatch the fetchUsers action when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchCustomers());
      } catch (error) {
        console.error('Error fetching customers:', error);
        // Handle the error gracefully, e.g., show a message to the user
      }
    };
  
    fetchData();
  }, [dispatch]);

  const handleEditClick = (customerId) => {
    setSelectedCustomerId(customerId);
    setEditModalOpen(true);
  };

  const [pageSize, setPageSize] = useState(5)
  const [rowId, setRowId] = useState(null)
  const data = customers 

  const columns = useMemo(
    () => [
      {
        field: 'first_name',
        headerName: 'First Name ',
        width: 100,
      },
      { field: 'last_name', headerName: 'Last Name', width: 100 },
      { field: 'email', headerName: 'Email', width: 170 },
      { field: 'password', headerName: 'Password', width: 170 },
      {
        field: 'valid_account',
        headerName: 'valid_account',
        width: 100,
        type: 'singleSelect',
        valueOptions: ['valid', 'notValid'],
        editable: true,
      },
      {
        field: 'active',
        headerName: 'Active',
        width: 100,
        type: 'boolean',
        editable: true,
      },
      {
        field: 'creation_date',
        headerName: 'Created At',
        width: 200,
        renderCell: (params) =>
          moment(params.row.createdAt).format('YYYY-MM-DD HH:MM:SS'),
      },
      { field: 'id', headerName: 'Id', width: 220 },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 200,
        renderCell: (params) => (
          <CustomerActions 
            onAddClick={handleAddClick}
            onDeleteClick={() => handleDeleteClick(params.row.id)}
            onEditClick={() => handleEditClick(params.row._id)} />
        ),
      },
    ],
    [rowId]
  );


  return (
    <Box sx={{height: 400, width: '95%', marginLeft: 4}}>
        <Typography variant='h3' component='h3' sx={{textAlign:'center', mt:3, mb:3}}>
            Manage Customers
        </Typography>
        {deleteSuccessMessage && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
            <Typography variant="body1" color="success">
              {deleteSuccessMessage}
            </Typography>
            <Button onClick={handleDismissMessage} color="inherit">
              Dismiss
            </Button>
          </Box>
        )}
        <Button
        variant="contained"
        color="primary"
        onClick={handleAddClick} 
        sx={{ marginBottom: 2 }}
      >
        Create
      </Button>
  
        
        <DataGrid
            columns={columns}
            rows={data}
            getRowId={(row) => row.id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize = {pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            getRowSpacing={params=>({
              top:params.isFirstVisible ? 0 : 5,
              bottom:params.isLastVisible ? 0 : 5
            })}
            data-mui-light={theme.palette.mode === 'light'}
              getRowClassName={(params) => {
                return theme.palette.mode === 'light' ? 'light-theme-row' : 'dark-theme-row';
            }}
            onCellEditCommit={(params) => {
              console.log('Cell edited:', params);
              setRowId(params.id);
            }}
            
            
        />
         <EditCustomerForm open={isEditModalOpen} onClose={() => setEditModalOpen(false)} />
    </Box>
  ); 
};

export default Customers;

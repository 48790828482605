import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "./theme"
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import PeopleIcon from '@mui/icons-material/People';
import InterestsIcon from '@mui/icons-material/Interests';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
// import {logout, } from '../../../features/auth2/authSlice'

import profil from './profil.png'

import {useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import { useEffect } from 'react';
//Redux

import { selectError, selectLoggedInUser } from '../../../features/auth2/authSlice';


const Item = ({ title, to, icon, selected, setSelected  }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  

  return (
    
    <MenuItem

      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected , setSelected] = useState("Dashboard");
  const error = useSelector(selectError);
  // const user = useSelector(selectLoggedInUser);
  
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    console.log()
    // useEffect(() => {
    //   if (!user) {
    //     console.log('You are not Logged In')
    //     navigate("/login");
        
    //   }
    // }, [user, navigate]);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}  >
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                {user.role}
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user" width="100px" height="100px" src={profil} style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
 {/* {user.name || 'Guest'} */}
 {user.firstName}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {user.role}
                </Typography>
              </Box>
            </Box>
          )}

          {/* *********Dashboard icone ***************** */}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<SpaceDashboardIcon />}
              selected={selected}
              setSelected={setSelected}
            />
{/* Here we add the topography  */}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              {/* Data */}
            </Typography>
                        {/* *****************OrderMangement Icon **************** */}
            <Item
              title="Orders"
              to="/orders"
              icon={<ShoppingCartCheckoutIcon />}
              selected={selected}
              setSelected={setSelected}
            />

                                    {/* *****************Customers Icon **************** */}

            <Item
              title="Customers"
              to="/customers"
              icon={<PeopleIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/* *********************categoories ******************** */}
            <Item
              title="Categories"
              to="/categories"
              icon={<InterestsIcon />}
              selected={selected}
              setSelected={setSelected}
            />


            {/* ************************Transcretion ******************** */}
            <Item
              title="Transaction"
              to="/transaction"
              icon={<ReceiptLongIcon />}
              selected={selected}
              setSelected={setSelected}
            />


               <Item
              title="Brand"
              to="/brand"
              icon={<StarBorderIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Products
            </Typography>
            {/* ********************product Icon *********** */}
            <Item
              title="Products management"
              
              to="/products"
              icon={<AddShoppingCartIcon />}
              selected={selected}
              setSelected={setSelected}
            />
                        <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Users
            </Typography>
            {/* ******************************Users management********* */}
            <Item
              title="Users Management"
              to="/users"
              icon={<GroupAddIcon />}
            selected={selected}
            setSelected={setSelected}
            />
            <Item
            title="Line Chart"
            to="/line"
            icon={<TimelineOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
            />
            <Item
            title="Geography Chart"
            to="/geography"
            icon={<MapOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
            />
        </Box>
        </Menu>
    </ProSidebar>
    </Box>
);
};

export default Sidebar;
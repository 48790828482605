import axios from 'axios';

const API_URL = '/api/orders/';

const createOrders = async (orderData, token) => {
  const response = await axios.post(API_URL, orderData);
  return response.data;
};

const fetchOrders = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const deleteOrder = async (orderId) => {
  try {
    const response = await axios.delete(`${API_URL}${orderId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting order:', error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

const orderService = {
  createOrders,
  fetchOrders,
  deleteOrder
};

export default orderService;
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import './woman.css'
import  Navigation from '../../../components/LandingPageComponents/global/Navigation'
import Footer from '../../../components/LandingPageComponents/global/footer';

import image1 from './woman-fashion.jpg'
import winter from './img/winter.jpg'
import summer from './img/summer.jpg'
import autumn from './img/autumn.jpg'
import spring from './img/spring.jpg'
import parka from './img/parka.png'
import shorts from './img/shorts.png'
import autumngirl from './img/autumn-girl.png'
import springgirl from './img/springgirl.png'


function Woman() {

 
  useEffect(() => {
    // GSAP animation for loading-page
    gsap.fromTo(
      '.loading-page',
      { opacity: 1 },
      {
        opacity: 0,
        display: 'none',
        duration: 1.5,
        delay: 3.5,
      }
    );

    // GSAP animation for logo-name
    gsap.fromTo(
      '.logo-name',
      {
        y: 50,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 2,
        delay: 0.5,
      }
    );

    gsap.registerPlugin(ScrollTrigger)
    


  }, []);


  return (
    <>

      <div className='loading-page'>
        <div className="logo">
        <svg id="svg" xmlns="http://www.w3.org/2000/svg" height="5em" viewBox="0 0 384 512">
            <path d="M128 64c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32s-14.3 32-32 32H293.3L160 416h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H90.7L224 96H160c-17.7 0-32-14.3-32-32z"/>
        </svg>
        <svg id="svg" xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512">
          <path d="M19.7 34.5c16.3-6.8 35 .9 41.8 17.2L192 364.8 322.5 51.7c6.8-16.3 25.5-24 41.8-17.2s24 25.5 17.2 41.8l-160 384c-5 11.9-16.6 19.7-29.5 19.7s-24.6-7.8-29.5-19.7L2.5 76.3c-6.8-16.3 .9-35 17.2-41.8z"/>
        </svg>
        <svg id="svg" xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512">
          <path d="M64 32C28.7 32 0 60.7 0 96V256 416c0 35.3 28.7 64 64 64H288c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V288H224c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V96H288c17.7 0 32-14.3 32-32s-14.3-32-32-32H64z"/>
        </svg>
        <svg id="svg" xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512">
          <path d="M64 32C28.7 32 0 60.7 0 96V288 448c0 17.7 14.3 32 32 32s32-14.3 32-32V320h95.3L261.8 466.4c10.1 14.5 30.1 18 44.6 7.9s18-30.1 7.9-44.6L230.1 309.5C282.8 288.1 320 236.4 320 176c0-79.5-64.5-144-144-144H64zM176 256H64V96H176c44.2 0 80 35.8 80 80s-35.8 80-80 80z"/>
        </svg>
        </div>

        <div className='name-container'>
          <div className='logo-name'>Store</div>
        </div>
      </div>

  
   
      <section className="woman-section">
        
          <div className="bg">
            <img src={image1} alt=""></img>
          </div>
          <div className="woman-contenu">
            <span className="title">Iver</span><br></br>
            <span className="sub-title">For our Ladies</span>
          </div>
      </section>




      <section className="winterClothes">
          <div className="bg2">
            <img className='w-img' src={winter} alt="" />
            <div className='winter-content winter'>
              <Link to="/product">Winter</Link>
            </div>
            <div className="model" >
              <img  src={parka} alt="" />
            </div>
            <div className="description" >
              Complete your winter wardrobe with our versatile collection of scarves, hats, and gloves. Made from soft and insulating fabrics, these accessories not only keep you warm but also add a touch of flair to your ensemble
            </div>
            <div className="slog">
              Elevate Winter Elegance, Warmly.
            </div>
          </div>
      </section>

      
      <section className="winterClothes">
          <div className="bg2">
            <img className='w-img' src={summer} alt="" />
            <div className='summer-content summer'>
              summer
            </div>
            <div className="model" >
              <img  src={shorts} alt="" />
            </div>
            <div className="s-description" >
              Complete your winter wardrobe with our versatile collection of scarves, hats, and gloves. Made from soft and insulating fabrics, these accessories not only keep you warm but also add a touch of flair to your ensemble
            </div>
            <div className="slog">
              Embrace the Heat with Our Summerwear Beat!
            </div>
          </div>
      </section>

      <section className="winterClothes">
          <div className="bg2">
            <img className='w-img' src={autumn} alt="" />
            <div className='summer-content summer'>
              autumn
            </div>
            <div className="model" >
              <img  src={autumngirl} alt="" />
            </div>
            <div className="description" >
              Complete your winter wardrobe with our versatile collection of scarves, hats, and gloves. Made from soft and insulating fabrics, these accessories not only keep you warm but also add a touch of flair to your ensemble
            </div>
            <div className="slog">
              Fall into Fashion: Embrace the Cozy Elegance of Autumn with
            </div>
          </div>
      </section>

      <section className="winterClothes">
          <div className="bg2">
            <img className='w-img' src={spring} alt="" />
            <div className='summer-content summer'>
              spring
            </div>
            <div className="model" >
              <img  src={springgirl} alt="" />
            </div>
            <div className="description" >
              Complete your winter wardrobe with our versatile collection of scarves, hats, and gloves. Made from soft and insulating fabrics, these accessories not only keep you warm but also add a touch of flair to your ensemble
            </div>
            <div className="slog">
              Embrace the Heat with Our Summerwear Beat!
            </div>
          </div>
      </section>
    




    </>
  );
}

export default Woman
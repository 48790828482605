import axios from 'axios';

const API_URL = '/api/customers';

// Create a new user
const createCustomer = async (customerData, token) => {
  const response = await axios.post(API_URL, customerData);
  return response.data;
};

const loginCustomer = async (loginData) => {
  const response = await axios.post(`${API_URL}/login`, loginData);
  return response.data;
};

// Fetch a list of users
const fetchCustomers = async (token) => {
  const response = await axios.get(API_URL);
  return response.data;
};

const updateCustomer = async (customerId, customerData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.put(`${API_URL}/${customerId}`, customerData, config);

    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteCustomer = async (customerId, token) => {

  const response = await axios.delete(`${API_URL}/${customerId}`);

  return response.data;
};

const customerService = {
  createCustomer,
  fetchCustomers,
  updateCustomer,
  deleteCustomer,
  loginCustomer
};

export default customerService;

import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const EditProductForm = ({ open, onClose, productId, onUpdate }) => {
  const [imageFileName, setImageFileName] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    sku: '',
    price: 0,
    quantity: 0,
    productImage: null,
    description: '',
    subcategoryName: '',
    discountPrice: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the product data by ID
        const response = await fetch(`/api/products/${productId}`);
        const productData = await response.json();

        // Update the form data with the fetched product data
        setFormData({
          name: productData.productName,
          sku: productData.sku,
          price: productData.price,
          quantity: productData.quantity,
          productImage: productData.productImage,
          description: productData.shortDescription,
        });

        setImageFileName(productData.productImage ? productData.productImage.name : '');
      } catch (error) {
        console.error('Error fetching product data:', error.message);
      }
    };

    if (open && productId) {
      fetchData();
    }
  }, [open, productId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      productImage: file,
    }));
    setImageFileName(file ? file.name : '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    

  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h6">Edit Product</Typography>
        <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField label="Product Name" name="name" value={formData.name} onChange={handleChange} fullWidth margin="normal" />
          <TextField label="Product Sku" name="sku" value={formData.sku} onChange={handleChange} fullWidth margin="normal" />
          <TextField label="Description" name="description" value={formData.description} onChange={handleChange} fullWidth multiline rows={4} margin="normal" />
          <TextField label="Price" name="price" type="number" value={formData.price} onChange={handleChange} fullWidth margin="normal" />
          <TextField label="Quantity" name="quantity" value={formData.quantity} onChange={handleChange} fullWidth margin="normal" />
          <input accept="image/*" id="contained-button-file" multiple type="file" onChange={(e) => handleChangeImage(e)} style={{ display: 'none' }} />
          <label htmlFor="contained-button-file">
            <Button variant="contained" component="span" sx={{ marginTop: 2 }}>
              Upload Image
            </Button>
          </label>

          {/* Display the image file name */}
          {imageFileName && (
            <Typography variant="subtitle2" color="textSecondary" sx={{ marginLeft: 2 }}>
              {imageFileName}
            </Typography>
          )}
          <br />
          {/* Add more fields as needed */}
          <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 2 }}>
            Save Changes
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditProductForm;

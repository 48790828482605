import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { addToCart, getTotals } from "../../../features/Cart/cartSlice";
import { Card, CardHeader, CardBody, CardFooter, Typography, Dialog, DialogHeader, DialogBody, DialogFooter, Avatar, IconButton, Button } from "@material-tailwind/react";
import { selectAllProducts, fetchProducts, selectProductListStatus } from '../../../features/products/productSlice';
import './product.css';
import ProductDialog from './ProductDialog'
import { NavigateBefore } from '@mui/icons-material';
import { AiOutlineUserAdd } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";

const Products = () => {
  const dispatch = useDispatch();
  const products = useSelector(selectAllProducts);
  const status = useSelector(selectProductListStatus);  
  const navigate = useNavigate();

  const [filter, setFilter] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [details, setDetails] = useState(null); // State to store product details
  const [openDialog, setOpenDialog] = useState({});
  const cartTotalQuantity = useSelector(state => state.cart.cartTotalQuantity);
 
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    // Apply the filter when the products or filter change
    const filtered = products.filter(product =>
      product.productName.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [products, filter]);

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
    dispatch(getTotals());
  };

  const fetchProductById = async (productId) => {
    try {
      const response = await axios.get(`/api/products/${productId}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleOpen = (id) => {
    setOpenDialog((prev) => ({ ...prev, [id]: true }));
  };

  const handleClose = (id) => {
    setOpenDialog((prev) => ({ ...prev, [id]: false }));
    // Use history.goBack() to go back to the previous page
    navigate(-1);
    navigate('/product');
  };
  const handleIsFavorite = () => setIsFavorite((cur) => !cur);
  
  
  return (
    <>

      <div className='full-page '>

        <div className='product-title'>              
          <Typography variant='h1' color='white' style={{ marginLeft: '-120px' }} >Get inspired</Typography>
          <input type="text" placeholder="Search by product name" value={filter} onChange={(e) => setFilter(e.target.value)}  />
        </div>

        <div className='product-cards'>
          <div className='product-container' id="filterable-cards">        
                {status === 'loading' ? (<Typography color="white">Loading...</Typography>) : null}
                {filteredProducts.length === 0 ? (
                  <Card shadow={false} className="relative grid h-[39rem] w-full max-w-[80rem] items-end justify-center overflow-hidden text-center">
                    <CardHeader floated={false} shadow={false} color="transparent" className="absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://images.unsplash.com/photo-1483193722442-5422d99849bc?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8c29ycnl8ZW58MHx8MHx8fDA%3D')] bg-cover bg-center">
                      <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
                    </CardHeader>
                    <CardBody className="relative py-14 px-6 md:px-12">
                      <Typography variant="h2" color="white" className="mb-6 font-medium leading-[1.5]">
                        We're sorry, but the product you're looking for is currently out of stock. We understand this may be disappointing.
                      </Typography>
                      <Typography variant="h5" className="mb-4 text-gray-400">
                        Iver
                      </Typography>
                    </CardBody>
                  </Card>
                ) : (
                  filteredProducts.map((product) => (
                    <Card key={product._id} shadow={false} onClick={() => handleOpen(product._id)} className="w-96 product-card">
                      <CardHeader shadow={false} floated={false} className="h-96">
                        <img
                          src={product.productImage}
                          alt="card-image"
                          className="h-full w-full object-cover"
                        />
                      </CardHeader>
                      <CardBody>
                        <div className="mb-2 flex items-center justify-between">
                          <Typography color="white" className="font-medium">
                            {product.productName}
                          </Typography>
                          <Typography color="white" className="font-medium">
                            ${product.price}
                          </Typography>
                        </div>
                        <Typography
                          variant="small"
                          color="white"
                          className="font-normal opacity-75"
                        >
                          With plenty of talk and listen time, voice-activated Siri access, and an available wireless charging case.
                        </Typography>
                      </CardBody>
                      <ProductDialog open={openDialog[product._id]} handleOpen={handleOpen} handleClose={handleClose} productId={product._id} />

                      <CardFooter className="pt-0">
                      
                          <Button
                            ripple={false}
                            fullWidth={true}
                            color="white"
                            className="add-btn shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100"
                            onClick={(e) => {
                              e.stopPropagation();  // Add this line to prevent event propagation
                              handleAddToCart(product);
                          }}
                          >
                            Add to Cart
                          </Button>
                                    
                    </CardFooter>
                    </Card>
                  ))
                )}
          </div>
          <div>
            <div style={{  position: 'fixed', right: '60px', top: '90px'}} className="nav-links">
            <ul style={{ color: 'white', fontSize: '26px', display: 'flex', flexDirection: 'column', gap: '20px'  }}>
              <li>
                          <Link to="/Cart">
                          <span style={{color: 'white', fontSize: '17px',position: 'absolute', right: '-12px', top: '-10px'}}>{cartTotalQuantity}</span>
                            <FaShoppingCart />
                            
                          </Link>
              </li>
            </ul>
          </div>
            </div>
        </div>

      </div>
 
    </>
  );
};

export default Products;
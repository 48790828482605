import { useState } from "react";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../../components/BackOfficeComponents/global/theme";
import Sidebar from "../../../components/BackOfficeComponents/global/Sidebar";
import Topbar from "../../../components/BackOfficeComponents/global/Topbar";
import Table from '../../../components/BackOfficeComponents/customerTable/customerTable'
import './customer.css'
import React from 'react';

const Customers = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app2">
        <Sidebar isSidebar={isSidebar} />
          <main className="content2">
          <Topbar setIsSidebar={setIsSidebar} />
             <Table/>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Customers;

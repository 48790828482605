export function createUser(userData) {
    return new Promise(async (resolve) => {
      const response = await fetch('/api/users/', {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: { 'content-type': 'application/json' },
      });
      const data = await response.json();
    
    
      resolve({ data });
    });
  }
  
  export function loginUser(loginInfo) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch('/api/users/login', {
          method: 'POST',
          body: JSON.stringify(loginInfo),
          headers: { 'content-type': 'application/json' },
        });
        if (response.ok) {
          const data = await response.json();
          const user= data.user
          const  msg = data.message;
          console.log("msg ", msg)
          console.log("user",user)
          if (data) {
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('Role', JSON.stringify(data.user.role));
            localStorage.setItem('Token', JSON.stringify(data.access_Token));
            // localStorage.setItem('token', data.access_Token);

            localStorage.setItem('msg' , data.message)

            // localStorage.setItem('role', data.user.role);
            // console.log('Role:', data.user.role);

            // console.log('Token stored:', data.access_Token);
          }

          resolve({ data });
        } else {
          const error = await response.text();
          reject(error);
        }
      } catch (error) {
        reject( error );
      }
  
    });
  }

  export function logout() {
    localStorage.removeItem('Token')
    localStorage.removeItem('user');
    localStorage.removeItem('Role');


  }
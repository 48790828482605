// ProductDialog.js

import React, { useState, useEffect } from 'react';
import { Dialog, DialogHeader, DialogBody, DialogFooter, Typography, Avatar, IconButton, Button } from "@material-tailwind/react";
import axios from 'axios';

const ProductDialog = ({ open, handleOpen, productId, handleClose }) => {
  const [details, setDetails] = useState(null);

  useEffect(() => {
    const fetchProductById = async (productId) => {
      try {
        const response = await axios.get(`/api/products/${productId}`);
        setDetails(response.data);
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };

    if (open && productId) {
      fetchProductById(productId);
    }
  }, [open, productId]);

  return (
    <Dialog size="xl" open={open} handler={handleClose}>
      {details && (
        <>
          <DialogHeader className="justify-between">
            <h1>{details.productName}</h1>
            {/* ... Header content */}
          </DialogHeader>
          <DialogBody>
            {/* Display product details */}
            
            <img
              alt={details.productName}
              className="h-[28rem] w-[29rem] w-full rounded-lg object-cover object-center"
              src={details.productImage}
            />
          </DialogBody>
              <DialogFooter className="justify-between">
               <div className="flex items-center gap-16">
                   <div>
                     <Typography variant="small" color="gray" className="font-normal">
                       Views
                     </Typography>
                     <Typography color="blue-gray" className="font-medium">
                       44,082,044
                     </Typography>
                   </div>
                   <div>
                     <Typography variant="small" color="gray" className="font-normal">
                       Downloads
                     </Typography>
                     <Typography color="blue-gray" className="font-medium">
                       553,031
                     </Typography>
                   </div>
                 </div>
                 <Button
                   size="sm"
                   variant="outlined"
                   color="blue-gray"
                   className="mr-5 flex items-center"
                 >
                   Share
                 </Button>
               </DialogFooter>
        </>
      )}
    </Dialog>
  );
};

export default ProductDialog;

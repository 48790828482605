import React from 'react'
import { Link } from "react-router-dom";




function woman() {

  const Navigation = () => (
    <div className="nav2">
      <div className="links">
        <ul>
          <li><Link to="/landing">Home</Link></li>
          <li><Link to="/">Services</Link></li>
          <li><Link to="/login">About</Link></li> 
        </ul>
      </div>
      <div className="links">
        <ul>
          <li><Link to="/">IVER</Link></li>
        </ul>
      </div>
      <div className="links">
        <ul>
          <li><Link to="/">SignUp</Link></li>
        </ul>
      </div>
    </div>
  );




  return (
    <>
      <section className="first-section">
          <Navigation />

          <div className="bg">
            <img ></img>
          </div>

          <div className="contenu">
            <span className="title">Iver</span><br></br>
            <span className="sub-title">For our Chaps</span>
          </div>
          
        </section>


    </>
  );
}

export default woman

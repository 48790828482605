import React, {useState} from 'react'
import { Card, CardHeader, CardBody, CardFooter, Typography, Input, Avatar, Button} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";

import { clearCart, removeFromCart} from "../../../features/Cart/cartSlice";
import {Link, useNavigate} from "react-router-dom";
import './check.css'

function Check() {
  const cartItems = useSelector(state => state.cart.cartItems);
  const [isCommandSent, setIsCommandSent] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const handleSendCommand = () => {
    window.alert('Your command has been sent successfully!');

    dispatch(clearCart());

    navigate('/product');
  };
  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
  };

  const totalAmount = cartItems.reduce((total, item) => total + item.price * item.cartQuantity, 0);
  return (
    <div className='main-checkout-page'>
      <div className='check-process'>
        <div className="check-form">
        <Card className=" w-[500px]">
      <CardHeader
        variant="gradient"
        color="gray"
        className="mb-4 grid h-28 place-items-center"
      >
        <Typography variant="h3" color="white">
          Check Out
        </Typography>
      </CardHeader>
      <CardBody className="flex flex-col gap-4">
          <div className="flex gap-4"> {/* Add a container to hold the two inputs */}
            <Input label="First Name" size="lg" flex="1" /> {/* Adjust the width of the inputs */}
            <Input label="Last Name" size="lg" flex="1" />
          </div>
        <Input label="Email" size="lg" />
        <Input label="Phone Number" size="lg" />
        <Input label="Shipping Adress" size="lg" />
      </CardBody>
      <CardFooter className="pt-0">
        <Button variant="gradient" fullWidth onClick={handleSendCommand}>
          Send Your Command
        </Button>
      </CardFooter>
    </Card>
        </div>
        <div className="check-details">
            <Card color="transparent" shadow={false} className="w-full max-w-[26rem]">
              <CardHeader
                color="transparent"
                floated={false}
                shadow={false}
                className="mx-0 flex items-center gap-4 pt-0 pb-8"
              >
              </CardHeader>
              <CardBody className="mb-6 p-0">
              {cartItems.map(cartItem => (
                <div key={cartItem._id} className="flex items-center gap-4 p-4 border-b border-gray-200">
                <img src={cartItem.productImage} alt={cartItem.productName} className="w-16 h-16 object-cover rounded" />
                <div>
                  <Typography variant="h5" color="white">{cartItem.productName}</Typography>
                  <Typography color="white">Quantity: {cartItem.cartQuantity}</Typography>
                  <Typography color="white">Price: ${cartItem.price}</Typography>
                </div>
                <button onClick={() => handleRemoveFromCart(cartItem)}>
                            Remove
                  </button>
              </div>
                ))}
              </CardBody>
              <CardFooter className="pt-0">
                <div className='check-buy'>
                  <Typography color='white'>Your Total</Typography>
                  <Typography>{`$${totalAmount.toFixed(2)}`}</Typography>
                </div>
                <Link  to={'/product'} className="back-to-shopping-link">Back to shopping</Link>                    
            </CardFooter>
            </Card>
        </div>
      </div>
    </div>
  )
}
export default Check
import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import { fetchProducts, deleteProduct } from '../../../features/products/productSlice';
import '../userTable/Tables.css';
import { useTheme } from '@mui/material/styles';
import ProductActions from './ProductActions';
import EditProductForm from './EditProductForm';
import AddProductForm from './AddProductForm';


// Define the ProductTable component
const ProductTable = ({ onAddClick }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  // Retrieve products from the Redux store
  const products = useSelector((state) => state.product.products);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);

  const handleAddClick = () => {
    setAddModalOpen(true);
    setSelectedProductId(null);
  };

  
    const handleDeleteClick = async (productId) => {
      try {
        // Dispatch the deleteProduct action
        await dispatch(deleteProduct(productId));
        // Set the success message
        setDeleteSuccessMessage('Product deleted successfully');

        await dispatch(fetchProducts());
      } catch (error) {
        console.error('Error deleting product:', error.message);
      }
    };

    const handleDismissMessage = () => {
      // Clear the success message
      setDeleteSuccessMessage(null);
    };
  

  // Fetch products on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Dispatch the fetchProducts action
        await dispatch(fetchProducts());
       
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [dispatch]);

  const handleEditClick = (productId) => {
    setSelectedProductId(productId);
    setEditModalOpen(true);
  };

  const [pageSize, setPageSize] = useState(5);

  const columns = useMemo(
    () => [
      { field: 'productName', headerName: 'Product Name', width: 150 },
      { field: 'sku', headerName: 'SKU', width: 100 },
      { field: 'price', headerName: 'Price', width: 100 },
      { field: 'quantity', headerName: 'Quantity', width: 100 },
      {
        field: 'productImage',
        headerName: 'Product Image',
        width: 120,
        renderCell: (params) => (
          <img src={params.row.productImage} alt={params.row.productName} style={{ width: '100%', height: 'auto' }} />
        ),
      },
      {
        field: 'subcategoryName',
        headerName: 'subcategoryName',
        width: 150,
        editable: true,
      },
      {
        field: 'active',
        headerName: 'Active',
        width: 100,
        type: 'boolean',
        editable: true,
      },
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        width: 200,
        renderCell: (params) => moment(params.row.updatedAt).format('YYYY-MM-DD HH:MM:SS'),
      },
      { field: '_id', headerName: 'Id', width: 220 },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 400,
        renderCell: (params) => (
          <ProductActions
            onAddClick={handleAddClick} 
            onEditClick={() => handleEditClick(params.row._id)}
            onDeleteClick={() => handleDeleteClick(params.row._id)}
          />
        ),
      },
    ],
    [] 
  );

  return (
    <Box sx={{ height: 400, width: '95%', marginLeft: 4 }}>
      <Typography variant="h3" component="h3" sx={{ textAlign: 'center', mt: 3, mb: 3 }}>
        Manage Products
      </Typography>

      {deleteSuccessMessage && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <Typography variant="body1" color="success">
            {deleteSuccessMessage}
          </Typography>
          <Button onClick={handleDismissMessage} color="inherit">
            Dismiss
          </Button>
        </Box>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleAddClick} 
        sx={{ marginBottom: 2 }}
      >
        Create
      </Button>
      <DataGrid
        columns={columns}
        rows={products}
        getRowId={(row) => row._id}
        rowsPerPageOptions={[5, 10, 20]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        getRowSpacing={(params) => ({
          top: params.isFirstVisible ? 0 : 5,
          bottom: params.isLastVisible ? 0 : 5,
        })}
        data-mui-light={theme.palette.mode === 'light'}

        getRowClassName={(params) => (theme.palette.mode === 'light' ? 'light-theme-row' : 'dark-theme-row')}
        onSelectionModelChange={(newSelection) => {
          setSelectedProductId(newSelection.selectionModel[0]);
        }}

        onSelectionModelChange={(newSelection) => {
          setSelectedProductId(newSelection.selectionModel[0]);
          // Clear the success message when a new selection is made
          setDeleteSuccessMessage(null);
        }}

      />
      <EditProductForm open={isEditModalOpen} productId={selectedProductId} onClose={() => setEditModalOpen(false)}  />
      <AddProductForm open={isAddModalOpen} productId={selectedProductId} onClose={() => setAddModalOpen(false)}  />
    </Box>
  );
};

// Export the ProductTable component
export default ProductTable;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import productService from './productService';

const initialState = {
  products: [],
  isError: false,
  isSuccess: false,
  status: 'idle',
  selectedProduct: null,
  categories: [],
  totalItems: 0,
  subCategories: [],

  isLoading: false,
  message: '',
};

export const deleteProduct = createAsyncThunk('products/delete', async (productId, thunkAPI) => {
  try {
    return await productService.deleteProduct(productId);
    
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


//FetchProductById 
export const fetchProductByIdAsync = createAsyncThunk('product/fetchId', async (productId, thunkAPI) => {
  try {
    const product = await productService.fetchProductById(productId);

    return product ;
    
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


export const fetchProductsByFiltersAsync = createAsyncThunk( 'product/fetchProductsByFilters', async ({ filter, sort, pagination }) => {
    const response = await productService.fetchProductsByFilters(filter, sort, pagination);
    return response.data;
  }
);


//Fetch The Products Fiolters by their Categories  make sure to get back to this one later
// export const fetchProductsByFiltersAsync = createAsyncThunk(
//   'product/fetchProductsByFilters',
//   async ({ filter, sort, pagination}) => {
//     const response = await productService.fetchProductsByFilters(filter, sort, pagination);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );


//Fetch Categories 
// export const fetchCategoriesAsync = createAsyncThunk(
//   'product/fetchCategories',
//   async () => {
//     const response = await productService.fetchCategories();
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );


export const fetchCategoriesAsync = createAsyncThunk('categories/fetch', async (_, thunkAPI) => {
  try {
    //const token = thunkAPI.getState().auth.user.token;
    
    const categories = await productService.fetchCategories();
    return categories;
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//fetch SubCategories 
export const fetchSubCategoriesAsync = createAsyncThunk('subCategories/fetch', async (_, thunkAPI) => {
  try {
    //const token = thunkAPI.getState().auth.user.token;
    
    const subCategories = await productService.fetchSubCategories();
    return subCategories;
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


// Fetch products from your API
export const fetchProducts = createAsyncThunk('products/fetch', async (_, thunkAPI) => {
  try {
    const products = await productService.fetchProducts(); // Replace with your actual fetch function
    return products;
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createProduct = createAsyncThunk('products/create', async (productData, thunkAPI) => {
  try {
    const newProduct = await productService.createProduct(productData);
    return newProduct;
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateProduct = createAsyncThunk('products/update', async ({ productId, productData }, thunkAPI) => {
  try {
    const updatedProduct = await productService.updateProduct(productId, productData);
    return updatedProduct;
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});






export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchCategoriesAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchCategoriesAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.categories = action.payload;
    })
    .addCase(fetchSubCategoriesAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchSubCategoriesAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.subCategories = action.payload;
    })
    .addCase(fetchProductsByFiltersAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchProductsByFiltersAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.products = action.payload.products;
      state.totalItems = action.payload.totalItems;
    })
      .addCase(fetchProducts.pending, (state) => {
        state.isLoading = true;
        state.status = 'loading';
        state.isError = false; 
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.status = 'fulfilled';
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.status = 'loading';
        state.message = action.payload;
      })
      .addCase(createProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.products.push(action.payload);
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const updatedProductIndex = state.products.findIndex((product) => product._id === action.payload._id);
        if (updatedProductIndex !== -1) {
          state.products[updatedProductIndex] = action.payload;
        }
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.products = state.products.filter((product) => product._id !== action.payload);
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchProductByIdAsync.pending, (state) => {
        state.status = 'loading';
        // state.selectedProduct = null;

      })
      .addCase(fetchProductByIdAsync.fulfilled, (state, action) => {
        console.log('fullfiled');
        state.status = 'idle';
        state.selectedProduct = action.payload;
      });
  },
});

export const selectProductById = (state) => state.product.selectedProduct;
export const selectProductListStatus = (state) => state.product.status;

export const selectTotalItems = (state) => state.product.totalItems;

// export const { clearSelectedProduct } = productSlice.actions;
export const selectAllProducts = (state) => state.product.products;

export const selectCategories = (state) => state.product.categories;
export const selectSubCategories = (state) => state.product.subCategories;

export const { reset } = productSlice.actions;
export default productSlice.reducer;

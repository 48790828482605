import { Box, Fab } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { green, red } from '@mui/material/colors';
import '../../../pages/BackOffice/users/users.css'

const CategorieActions = ({ params, onAddClick, onDeleteClick, onEditClick }) => {

  const handleDeleteClick = () => {
    onDeleteClick();
  };

  const handleEditClick = () => {
    onEditClick();
  };
 
  return (
    <Box className="action" sx={{ m: 1, position: 'relative'}} >
      
        <Fab color="primary" sx={{ width: 40, height: 40, marginright: 20, bgcolor: green[500], '&:hover': { bgcolor: green[700] }, }} onClick={handleEditClick}>
          <EditIcon />
        </Fab>
        <Fab color="primary" sx={{ width: 40, height: 40, bgcolor: red[500], '&:hover': { bgcolor: red[700] } }} onClick={handleDeleteClick}>
          <DeleteIcon />
        </Fab>


    </Box>
  );
};

export default CategorieActions;
import { createSlice, createAsyncThunk  ,createAction} from '@reduxjs/toolkit';
import authService from './authService';

const initialState = {
  isAuthenticated: false,
  isAuthorized :false,
  loggedInUserToken: null, // this should only contain user identity => 'id'/'role'

  user: {
    name: null,
    // other user properties
  },
  isLoading: false,
  isError: false,
  message: '',
};

// Register user
export const setLoggedIn = createAction('auth2/setLoggedIn', (isAuthenticated) => {
  return { payload: isAuthenticated };
});
export const register = createAsyncThunk('auth2/register', async (userData, thunkAPI) => {
  try {
    return await authService.register(userData);
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Login user
// export const loginUserAsync = createAsyncThunk(
//   'auth2/login',
//   async (loginInfo, { rejectWithValue }) => {
//     try {
//       const response = await loginUser(loginInfo);
//       return response.data;
//     } catch (error) {
//       console.log(error);
//       return rejectWithValue(error);
//     }
//   }
// );
export const login = createAsyncThunk('auth2/login', async (userData, thunkAPI) => {
    try {
      const user = await authService.login(userData);
      thunkAPI.dispatch(setUserName(user.name)); 
      thunkAPI.dispatch(setLoggedIn(true)); // Set isAuthenticated to true on successful login
      thunkAPI.dispatch(setToken(user.token));
      
      return user.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
});

export const setToken = createAction('auth2/setToken', (token) => {
  return { payload: token };
});

export const setUserName = createAction('auth2/setUserName', (name) => {
  return { payload: name };
});

export const logout =  createAsyncThunk('auth2/logout', async () => {
  await authService.logout();
  localStorage.removeItem('token'); 
});

const authSlice = createSlice({
  name: 'auth2',
  initialState,
  reducers: {
    reset: (state) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.isError = false;
      state.message = '';
    },
    setLoggedIn: (state, action) => {
      state.isAuthenticated = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(logout.fulfilled, (state) => {
        state.user = null
      })

      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.loggedInUserToken = action.payload;

        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});
export const selectLoggedInUser = (state) => state.auth.loggedInUserToken;
export const user = (state) => state.auth.user;


export const { reset } = authSlice.actions;
export default authSlice.reducer;

// import { createAction,createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import authService from './authService';
// import axios from 'axios';
// axios.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
// const initialState = {
//   isAuthenticated: false,
//   user: {
//     name: null,
//     // other user properties
//   },
//   isLoading: false,
//   isError: false,
//   message: '',
// };

// export const setLoggedIn = createAction('auth2/setLoggedIn', (isAuthenticated) => {
//   return { payload: isAuthenticated };
// });
// // Register user
// export const register = createAsyncThunk('auth2/register', async (userData, thunkAPI) => {
//   try {
    
//     return await authService.register(userData);
//   } catch (error) {
//     const message =
//       (error.response &&
//         error.response.data &&
//         error.response.data.message) ||
//       error.message ||
//       error.toString();
//     return thunkAPI.rejectWithValue(message);
//   }
// });

// // Login user
// export const login = createAsyncThunk('auth2/login', async (userData, thunkAPI) => {
//     try {
//       const user = await authService.login(userData);
//       thunkAPI.dispatch(setUserName(user.name)); 
//       // thunkAPI.dispatch(setUserRoles(user.roles));
//       // thunkAPI.dispatch(setLoggedIn(true));
//       thunkAPI.dispatch(setLoggedIn(true)); // Set isAuthenticated to true on successful login

//       thunkAPI.dispatch(setToken(user.token));

//       return user;
//     // return await authService.login(userData);
//   } catch (error) {
//     const message =
//       (error.response &&
//         error.response.data &&
//         error.response.data.message) ||
//       error.message ||
//       error.toString();
//     return thunkAPI.rejectWithValue(message);
//   }
// });

// export const setToken = createAction('auth2/setToken', (token) => {
//   return { payload: token };
// });
// export const setUserName = createAction('auth2/setUserName', (name) => {
//   return { payload: name };
// });
// export const logout =  createAsyncThunk('auth2/logout', async () => {
//   await authService.logout();
//   localStorage.removeItem('token'); 
//   setLoggedIn(false);
// } )

// const authSlice = createSlice({
//   name: 'auth2',
//   initialState,
//   reducers: {
//     reset: (state) => {
//       state.isAuthenticated = false;
//       state.isLoading = false;
//       state.isError = false;
//       state.message = '';
//     },
//     setLoggedIn: (state, action) => {
//       state.isAuthenticated = action.payload;
//   }
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(register.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(register.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.user = action.payload;
//       })
//       .addCase(register.rejected, (state, action) => {
//         state.isLoading = false;
//         state.isError = true;
//         state.message = action.payload;
//       })

//       .addCase(logout.fulfilled, (state) => {
//         state.user = null
//       })

//       .addCase(login.pending, (state) => {
//         state.isLoading = true;
//       })
//       .addCase(login.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.isAuthenticated = true;
//         state.user = action.payload;

//       })
//       .addCase(login.rejected, (state, action) => {
//         state.isLoading = false;
//         state.isError = true;
//         state.message = action.payload;
        
//       })
//   },
// });

// export const { reset } = authSlice.actions;
// export default authSlice.reducer;
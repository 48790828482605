import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { Modal, Box, TextField, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import {createCustomer} from '../../../features/customers/customerSlice'
import '../../../pages/BackOffice/users/users.css'



const EditCustomerForm = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    valid_account: '',
    active: '',
    creation_date: ''
  });

  const dispatch = useDispatch()
   
  const {first_name, last_name, email, password, valid_account, active, creation_date} = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }


  const handleSubmit = (event) => {
    event.preventDefault();
  
    dispatch(createCustomer(formData));

  // Reset form data
  setFormData({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    valid_account: '',
    active: '',
    creation_date: ''
  });

  onClose(); // Close the modal after submission
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'black', p: 4, width: 400, }}
      >
        <form onSubmit={handleSubmit}>
          <TextField className='input1' label="First Name" id="first_name" name="first_name" value={first_name} onChange={onChange}  fullWidth  />
          <TextField className='input1' label="Last Name" id='last_name' name="last_name" value={last_name} onChange={onChange} fullWidth />
          <TextField className='input1' label="Password" id='password' name="password" value={password} onChange={onChange} fullWidth />
          <TextField className='input1' label="Email" id='email' name='email' value={email} onChange={onChange} type='email' fullWidth  />
          <FormControl className='input1' fullWidth>
            <InputLabel>valid_accoun</InputLabel>
            <Select label="valid_account" id='rovalid_accountle' name='valid_account' value={valid_account} onChange={onChange}>
              <MenuItem value="Valid">Valid</MenuItem>
              <MenuItem value="notValid">notValid</MenuItem>
            </Select>
          </FormControl>
          <FormControl className='input1' fullWidth>
            <InputLabel>Status</InputLabel>
            <Select label="Status" id='active' name='active' value={active} onChange={onChange}>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="notActive">notActive</MenuItem>
            </Select>
          </FormControl>
          <Button type="submit" variant="contained" color="primary">
            Add User
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default EditCustomerForm;

import React, { Component } from "react";
import Slider from "react-slick";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import  "./landing.css"

import mpic1 from './imgs/vikings1.jpg'
import mpic2 from './imgs/vikings2.jpg'
import mpic3 from './imgs/vikings3.jpg'
import mpic4 from './imgs/vikings4.jpg'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default class AdaptiveHeight extends Component {
  render() {
    var settings = {
      className: "slider",
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      
    };
    return (
      <Box sx={{ flexGrow: 1 }} >
          <Grid container spacing={0} >
            <Grid item xs={12}>
              <Item  className="con">

                <div className="silder-container">
                <div className="s-title" >
                  <h2>Vikings Arrivals Soon</h2>
                </div>
                  <Slider {...settings}>
                  <div>
    <div className="wrapper">
      <div className="image-wrapper">
        <img src={mpic1} />
      </div>
      <div className="header-wrapper">
        <h1>Vikings</h1>
        <h3>See Project</h3>
      </div>
    </div>
  </div>
  <div>
    <div className="wrapper">
      <div className="image-wrapper">
        <img
          className="book-design-image"
          src={mpic2}
        />
      </div>
      <div className="header-wrapper">
        <h1>Vikings</h1>
        <h1>See More</h1>
      </div>
    </div>
  </div>
  <div>
    <div className="wrapper">
      <div className="image-wrapper">
        <img
          className="book-design-image"
          src={mpic3}
        />
      </div>
      <div className="header-wrapper">
        <h1>Vikings</h1>
        <h1>See More</h1>
      </div>
    </div>
  </div>
  <div>
    <div className="wrapper">
      <div className="image-wrapper">
        <img src={mpic4} />
      </div>
      <div className="header-wrapper">
        <h1>Vikings</h1>
        <h1>See More</h1>
      </div>
    </div>
  </div>
                  </Slider>
                </div>
              </Item>
            </Grid>
          </Grid>
      </Box>

    );
  }
}
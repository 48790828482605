import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth2/authSlice';
import authReducerr from '../features/auth/authSlice';

import userReducer from '../features/users/userSlice';
import orderReducer from '../features/orders/orderSlice';
import productReducer from '../features/products/productSlice';
import customerReducer from '../features/customers/customerSlice';
import categorieReducer from '../features/categories/categorieSlice';
import productsReducer, { productsFetch } from "../features/Cart/productsSlice";
import cartReducer, { getTotals } from "../features/Cart/cartSlice";
import { productsApi } from "../features/Cart/productsApi";


export const store = configureStore({
  reducer: {
    auth: authReducer,
    auth2: authReducerr,
    user: userReducer,
    order: orderReducer,
    product: productReducer,
    customer: customerReducer,
    categorie: categorieReducer,
    products: productsReducer,
    cart: cartReducer,
    [productsApi.reducerPath]: productsApi.reducer,

  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(productsApi.middleware),
});
store.dispatch(productsFetch());
store.dispatch(getTotals());

import React , {useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Box, Typography, Button} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid'
import moment from 'moment';
import { fetchCategories, deleteCategorie } from '../../../features/categories/categorieSlice'; // Replace with the correct path to your Redux slice and action
//import './Tables.css'
import { useTheme } from '@mui/material/styles';
import CategorieActions from './categorieActions'
import AddCategorieForm from './AddCategorieForm'
import EditCategorieForm from './EditCategorieModel';

const Categories = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  // Access user data and loading/error states from the Redux store
  const categories = useSelector((state) => state.categorie.categories);
  const isLoading = useSelector((state) => state.categorie.isLoading);
  const isError = useSelector((state) => state.categorie.isError);

  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedCategorieId, setSelectedCategorieId] = useState(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState(null);

  // Callback function for handling edit action
  const handleAddClick = () => {
    setAddModalOpen(true);
    setSelectedCategorieId(null);
  };
  
  const handleDeleteClick = async (categorieId) => {
    try{
      await dispatch(deleteCategorie(categorieId));

      setDeleteSuccessMessage('Categorie deleted successfully');

      await dispatch(fetchCategories());
    } catch (error) {
      console.error('Error deleting Categorie:', error.message);
    }

  };

  const handleDismissMessage = () => {
    setDeleteSuccessMessage(null);
  };


  // Dispatch the fetchUsers action when the component mounts
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchCategories());
      } catch (error) {
        console.error('Error fetching Categories:', error);
        // Handle the error gracefully, e.g., show a message to the user
      }
    };
  
    fetchData();
  }, [dispatch]);


  const handleEditClick = (categorieId) => {
    setSelectedCategorieId(categorieId);
    setEditModalOpen(true);
  };
  const [pageSize, setPageSize] = useState(5)
  const [rowId, setRowId] = useState(null)
  const data = categories 

  const columns = useMemo(
    () => [
      {
        field: '_id',
        headerName: 'Id',
        width: 300,
      },
      { field: 'category_name', headerName: 'category_name', width: 100 },
      {
        field: 'active',
        headerName: 'Active',
        width: 280,
        type: 'boolean',
        editable: true,
      },
      {
        field: 'creation_date',
        headerName: 'Created At',
        width: 300,
        renderCell: (params) =>
          moment(params.row.creation_date).format('YYYY-MM-DD HH:MM:SS'),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 200,
        renderCell: (params) => (
          <CategorieActions 
           onAddClick={handleAddClick}
           onDeleteClick={() => handleDeleteClick(params.row.id)}
           onEditClick={() => handleEditClick(params.row.id)} />
        ),
      },
    ],
    [rowId]
  );

  
  return (
    <Box sx={{height: 400, width: '95%', marginLeft: 4}}>
        <Typography variant='h3' component='h3' sx={{textAlign:'center', mt:3, mb:3}}>
            Manage Categories
        </Typography>

        {deleteSuccessMessage && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <Typography variant="body1" color="success">
            {deleteSuccessMessage}
          </Typography>
          <Button onClick={handleDismissMessage} color="inherit">
            Dismiss
          </Button>
        </Box>
      )}

        <Button
          variant="contained"
          color="primary"
          onClick={handleAddClick} 
          sx={{ marginBottom: 2 }}
        >
          Create
        </Button>
        <DataGrid
            columns={columns}
            rows={data}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize = {pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            getRowSpacing={params=>({
              top:params.isFirstVisible ? 0 : 5,
              bottom:params.isLastVisible ? 0 : 5
            })}
            data-mui-light={theme.palette.mode === 'light'}
              getRowClassName={(params) => {
                return theme.palette.mode === 'light' ? 'light-theme-row' : 'dark-theme-row';
            }}
            onCellEditCommit={(params) => {
              console.log('Cell edited:', params);
              setRowId(params.id);
            }}
            
            
        />
         <AddCategorieForm open={isAddModalOpen} onClose={() => setAddModalOpen(false)} />
         <EditCategorieForm open={isEditModalOpen} productId={selectedCategorieId} onClose={() => setEditModalOpen(false)}  />
    </Box>
  ); 
};

export default Categories;

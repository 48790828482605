import {useState} from 'react'
import { Box, Fab } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { green, red } from '@mui/material/colors';
import '../../../pages/BackOffice/users/users.css'

const UsersActions = ({params, rowId, onEditClick, onDeleteClick  }) => {
  const [loading, setLoading] = useState(false)
  const [success, isSuccess] = useState(false)

  const handleEditClick = async () => {
    
  };

  const handleDeleteClick = () => {
    onDeleteClick();
  };
 
  return (
    <Box className="action" sx={{ m: 1, position: 'relative'}} >

        {success ? (
          <Fab color="primary" sx={{ width: 40, height: 40, marginright: 20, bgcolor: green[500], '&:hover': { bgcolor: green[700] }, }} >
            <EditIcon />
          </Fab>
        ) : (
          <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            marginright: 20,
          }}
          disabled={params.id !== rowId || loading || params.isCellEditing} // Check if a cell is being edited
        >
          <EditIcon />
        </Fab>
        )}
        {loading && (
          <h2>loading</h2>
        )}

        <Fab color="primary" sx={{ width: 40, height: 40, bgcolor: red[500], '&:hover': { bgcolor: red[700] } }} onClick={handleDeleteClick}>
          <DeleteIcon />
        </Fab>

    </Box>
  );
};

export default UsersActions;
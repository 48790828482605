import { useState } from "react";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../../components/BackOfficeComponents/global/theme";
import Sidebar from "../../../components/BackOfficeComponents/global/Sidebar";
import Topbar from "../../../components/BackOfficeComponents/global/Topbar";
import Table from '../../../components/BackOfficeComponents/userTable/Tables'
import { useSelector } from 'react-redux';

import React from 'react';
import NotAuthorizedComponent from "../../../components/NotAuthorizedComponent";

const Users = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const role = JSON.parse(localStorage.getItem('Role'))
console.log('role',role)
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app2">
        <Sidebar isSidebar={isSidebar} />
          <main className="content2">
          <Topbar setIsSidebar={setIsSidebar} />
          {role !== "admin" ? <NotAuthorizedComponent /> :< Table />  }

             
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default Users;

import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { Modal, Box, TextField, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import {createCategorie} from '../../../features/categories/categorieSlice'
import '../../../pages/BackOffice/users/users.css'



const AddcategorieForm = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    category_name: '',
    active: ''
  });

  const dispatch = useDispatch()
   
  const {category_name,  active} = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(createCategorie(formData));

  // Reset form data
  setFormData({
    category_name: '',
    active: ''
  });

  onClose(); // Close the modal after submission
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'black', p: 4, width: 400, }}
      >
        <form onSubmit={handleSubmit}>
          <TextField className='input1' label="Category Name" id="category_name" name="category_name" value={category_name} onChange={onChange}  fullWidth  />
          <FormControl className='input1' fullWidth>
            <InputLabel>Status</InputLabel>
            <Select label="Status" id='active' name='active' value={active} onChange={onChange}>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="notActive">notActive</MenuItem>
            </Select>
          </FormControl>
          <Button type="submit" variant="contained" color="primary">
            Add Categorie
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default AddcategorieForm;

import {Box , IconButton , useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "./theme"
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { IoMdLogOut } from "react-icons/io";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import {useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import { useForm } from 'react-hook-form';
//Redux
import {signOutAsync , reset} from '../../../features/auth2/authSlice'
import { selectError, selectLoggedInUser } from '../../../features/auth2/authSlice';


const Topbar = () => {
   const dispatch = useDispatch();
  const error = useSelector(selectError);
  const user = useSelector(selectLoggedInUser);
  const message = localStorage.getItem('msg');   
   const navigate = useNavigate();
  
  
    // useEffect(() => {
    //   if (!user) {
    //     // localStorage.clear();
    //     navigate("/login");
    //   }
    // }, [user, navigate]);
  
    const onLogout = () => {
      dispatch(signOutAsync());
      navigate("/login");
    };
  
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    return(
        <Box display = "flex" justifyContent = "space-between" p={2}>
            {/* Search bar */}
            <Box display="flex"  backgroundColor={colors.primary[400]} borderRadius="6px">
            <IconButton type = "button" sx= {{ p :1}} style={{ color: colors.grey[100] }}>
                <SearchIcon />
            </IconButton>
            <InputBase sx ={{ml:2,flex: 1}} placeholder="Search" style={{ color: colors.grey[100]}}/>
            </Box>

            {/* Iconss Here  */}
            <Box display= "flex">
{/* if The user Selected the dark or the light Mode Condition   */}
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === 'dark' ? ( <DarkModeOutlinedIcon/> ) : (<LightModeOutlinedIcon/>)}
                </IconButton>

{/* Notification Button Icon */}
                <IconButton>
                    <NotificationsOutlinedIcon />
                </IconButton>
{/* Setting Icon */}
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
               
{/* PersonLined Icon */}
<Link to="/profile">
                <IconButton>
                    <PersonOutlinedIcon/>
                </IconButton>
                </Link>

{/* Log out Button */}
             <IconButton >
                    <IoMdLogOut onClick={onLogout}/>
                </IconButton>

            </Box>
        </Box>
    )
};

export default Topbar;
import { BrowserRouter as Router, Routes, Route, RouterProvider } from 'react-router-dom';
 import './App.css';
 
// import './index.css';

// BackOffice : 
import Dashboard2 from './pages/BackOffice/Dashboard/Dashboard'
// import Login from './pages/BackOffice/login/login';
import Login from './features/auth2/components/Login';
import UserProfile from './pages/BackOffice/Profile/UserProfile';
//import Login from './features/auth2/components/Login';
import Users from './pages/BackOffice/users/users'
import Orders from './pages/BackOffice/Orders/orders'
import Customers from './pages/BackOffice/Customers/customer'
import Categories from './pages/BackOffice/categories/categorie'
import Products from './pages/BackOffice/Products/products'
//import NotAuthorizedComponent from "./components/NotAuthorizedComponent"
import NotAuthorizedComponent from "./components/NotAuthorizedComponent"

// FrontOffice : 
import Parallax from './pages/FrontOffice/Landing/Landing'
import Women from './pages/FrontOffice/Women/page'
import Men from './pages/FrontOffice/mans/man'
import Kids from './pages/FrontOffice/Kids/kids'
import Register from './pages/FrontOffice/register/register';
import ProductDetails from './pages/FrontOffice/Productsdetails/productDetails';
//import Product from "./pages/FrontOffice/ProductPage/Product"
import Product from "./pages/FrontOffice/ProductPage/Product"
import Customer from './pages/FrontOffice/CustomerProfile/Customer'
import Clogin from './pages/FrontOffice/Clogin/login'
import Nav from './components/LandingPageComponents/Navbar/navbar'
import Footer from './components/LandingPageComponents/global/footer';
// //import ProductList from './features/products/components/ProductList';
import Check from './pages/FrontOffice/checkout/check'

//test

import Cart from './features/Cart/Cart'
import CustomCursor from './pages/FrontOffice/CustomCursor'
import SideBar from '../src/components/LandingPageComponents/ProductsPage/SideBar'
//protected
//import Protected from './features/auth2/components/protected'
import Navigation from './components/LandingPageComponents/global/Navigation';
import Protected from './features/auth2/components/protected'
//import UserProfile from './pages/BackOffice/Profile/UserProfile'

// import Customer Authentication Components
import Username from './components/Username';
import Password from './components/Password';
import RegisterC from './components/Register';
import ProfileC from './components/ProfileC';
import Recovery from './components/Recovery';
import Reset from './components/Reset';
import PageNotFound from './components/PageNotFound';
import { AuthorizeUser, ProtectRoute } from './middleware/auth'
import Product2 from './pages/FrontOffice/ProductPage/Product2'

function App() {
  return (
    <>
    <div className ='app'>
    <CustomCursor/>
   
    <Router>
    <Nav
    />
      <Routes >


        <Route path='/register' element={<Register />} />
        <Route path='/' element={<Parallax />} />
        <Route path='/women' element={<Women />} /> 
        <Route path='/man' element={<Men />} />
        <Route path='/kids' element={<Kids />} />
        <Route path='/productDetails/:id' element={<ProductDetails />} />
        <Route path='/Cart' element={<Cart />} />
        <Route path="/shop" element={<Product />}/>
        <Route path="/product" element={<Product2 />}/>
        <Route path='/check' element={<ProtectRoute><Check/></ProtectRoute>}></Route>
      

        {/* <Route path="/shop" element={<Shop />}/> */}

        {/* BackOffice */}
        <Route path='/login' element={<Login />} />
        <Route path='/dashboard' element={<Protected><Dashboard2 /></Protected>} />
        <Route path="users" element={<Protected><Users /></Protected>}/>
        <Route path="/orders" element={<Protected><Orders /></Protected>}/>
        <Route path="/customers" element={<Protected><Customers /></Protected>}/>
        <Route path="/categories" element={<Protected><Categories /></Protected>}/>
        <Route path="/products" element={<Protected><Products /></Protected>}/>
        <Route path="/profile" element={<Protected><UserProfile /></Protected>}/>

        {/* <Route path="/NotAuhorised" element={<NotAuthorizedComponent />}/> */}

        <Route path="/Username" element={<Username />}/>
        <Route path="/password" element={<ProtectRoute><Password /></ProtectRoute>} />
        <Route path="/RegisterC" element={<RegisterC />}/>
        <Route path="/Recovery" element={<Recovery />}/>
        <Route path="/ProfileC" element={<AuthorizeUser><ProfileC /></AuthorizeUser>}/>
        <Route path="*" element={<Reset />}/>
        <Route path="/PageNotFound" element={<PageNotFound />}/>
        
      </Routes>
     
   </Router>
  
   </div>
   </>
  );
}

export default App;

import axios from 'axios';

const API_URL = '/api/categories';

// Create a new user
const createCategorie = async (categorieData, token) => {
  const response = await axios.post(API_URL, categorieData);
  return response.data;
};

// Fetch a list of users
const fetchCategories = async (token) => {
  const response = await axios.get(API_URL);
  return response.data;
};

const updateCategorie = async (categorieId, categorieData, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.put(`${API_URL}/${categorieId}`, categorieData, config);

    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteCategorie = async (categorieId, token) => {

  const response = await axios.delete(`${API_URL}/${categorieId}`);

  return response.data;
};

const categorieService = {
  createCategorie,
  fetchCategories,
  updateCategorie,
  deleteCategorie
};

export default categorieService;

import axios from 'axios';
import api from '../api';

const API_URL = '/api/users/';


const register = async (userData) => {
  const response = await axios.post(API_URL, userData);

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data));
    localStorage.setItem('token', response.data.access_Token);

    }

  return response.data;
};

export function login(loginInfo) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(API_URL +'login', {
        method: 'POST',
        body: JSON.stringify(loginInfo),
        headers: { 'content-type': 'application/json' },
      });
      if (response.ok) {
        const data = await response.json();
    localStorage.setItem('user', JSON.stringify(response.data));
    localStorage.setItem('token', response.data.access_Token);
    console.log('Token stored:', response.data.access_Token);
        resolve({ data });
      } else {
        const error = await response.text();
        reject(error);
      }
    } catch (error) {
      reject( error );
    }

  });
}

// const login = async (userData) => {
//   const response = await axios.post(API_URL + 'login', userData);

//   if (response.data) {
//     localStorage.setItem('user', JSON.stringify(response.data));
//     localStorage.setItem('token', response.data.access_Token);
//     console.log('Token stored:', response.data.access_Token);


//   }

//   return response.data;
// };

// logout
const logout = () => {
  localStorage.removeItem('user')
}

export default {
  register,
  login,
  logout
};
// ReviewSection.js
import React from 'react';
import StarRating from './starRanking';
import './review.css';

const generateRandomText = () => {
  const texts = [
    "Outstanding product! The material is top-notch, and it's very comfortable to wear.",
    'Great hoodie, stylish and warm. Fits perfectly. Would recommend!',
    "Good product, but the sizing was a bit off for me. Consider sizing up.",
    "Awesome purchase! I'm completely satisfied with the quality and style.",
    'This hoodie exceeded my expectations. The design is fantastic!',
    'I love this hoodie. It has become my favorite winter essential.',
    'Perfect fit and excellent quality. Highly recommended!',
    'Comfortable and trendy. A great addition to my wardrobe.',
    'The hoodie arrived quickly, and I love the attention to detail.',
    'Highly impressed with the craftsmanship. Will buy again!',
    'This hoodie is a game-changer. I get compliments every time I wear it.',
    'Great value for money. I would buy it again without hesitation.',
  ];

  const author = [
    "Senior Saadia",
    'Senior Fathi',
    "Junior Imloul",
  ];

  // Randomly select a text from the array
  const randomIndex = Math.floor(Math.random() * texts.length);
  return texts[randomIndex];
};

const generateRandomAuthor = () => {
  const author = [
    "Senior Saadia",
    'Senior Fathi',
    "Senior Imloul",
  ];

  // Randomly select a text from the array
  const randomIndex = Math.floor(Math.random() * author.length);
  return author[randomIndex];
};
const ReviewSection = () => {
  const reviews = [
    { rating: 5, text: generateRandomText(), author: generateRandomAuthor() },
  ];

  return (
    <div className='review-section'>
      <div className='review-row'>
        {reviews.map((review, index) => (
          <div key={index} className='customer-review'>
            <h2 className='review-author'>- {review.author}</h2>
            <p className='review-text'>{review.text}</p>
            <StarRating totalStars={5} initialRating={review.rating} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewSection;

import { useEffect, useState} from "react";
import { AiFillCaretRight } from "react-icons/ai";
import SimpleSlider from "./Slider"
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { Link } from "react-router-dom";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import "./landing.css";
import  Navigation from '../../../components/LandingPageComponents/global/Navigation'



import vd6 from "./Vds/iver-women.mp4";
import vd7 from "./Vds/iver-men.mp4";
import vd8 from "./Vds/iver-kids.mp4";

import image1 from './imgs/winter_parka.jpeg'
import image2 from './imgs/autumn_w.jpg'
import image3 from './imgs/summer_w.jpg'
import image4 from './imgs/spring_w.webp'

import mpic1 from './imgs/m-pic1.jpg'
import mpic2 from './imgs/m-pic2.jpg'
import mpic3 from './imgs/m-pic3.jpg'



function Parallax3() {
  const { innerHeight } = window;
  const [currentVideo, setCurrentVideo] = useState(null);

  const getRatio = (el) => innerHeight / (innerHeight + el.offsetHeight);

  useEffect(() => {
      gsap.registerPlugin(ScrollTrigger);

      
      gsap.utils.toArray("section").forEach((section, i) => {
        section.bg = section.querySelector(".bg");

        gsap.fromTo(
          section.bg,
          {
            backgroundPosition: () =>
              i ? `50% ${-innerHeight * getRatio(section)}px` : "50% 0px",
          },
          {
            backgroundPosition: () =>
              `50% ${innerHeight * (1 - getRatio(section))}px`,
            ease: "none",
            scrollTrigger: {
              trigger: section,
              start: () => (i ? "top bottom" : "top top"),
              end: "bottom top",
              scrub: true,
              onEnter: () => {
                // When entering a new section, pause the previous video (if any)
                if (currentVideo) {
                  const prevVideo = document.getElementById(currentVideo);
                  if (prevVideo) {
                    prevVideo.pause();
                  }
                }
              },
              onLeave: () => {
                // When leaving a section, pause the video
                if (currentVideo) {
                  const prevVideo = document.getElementById(currentVideo);
                  if (prevVideo) {
                    prevVideo.pause();
                  }
                }
              },
            },
          }
        );
      });

      gsap.fromTo(
        '.title',
        {
          y: -50,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 2,
          delay: 0.5,
       
        }
    );
 
    gsap.fromTo(
      '.sub-title',
      {
        y: -50,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration: 2,
        delay: 0.5,
     
      }
  );
  gsap.fromTo(
    '.wrap',
    {
      x: 50,
      opacity: 0,
    },
    {
      x: 0,
      opacity: 1,
      duration: 2,
      delay: 1,
   
    }
);
gsap.fromTo(
  '.i-logo',
  {
    x: -50,
    opacity: 0,
  },
  {
    x: 0,
    opacity: 1,
    duration: 2,
    delay: 1,
 
  }
);

    // --------------------- GSAP Animation for Women Section ------------------------------- //
      gsap.fromTo(
        '.pic1',
        {
          y: -50,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 2,
          delay: 0.5,
          scrollTrigger: {
            trigger: '.pic1',
            start: 'top 80%',
            end: '+=300',
            scrub: 1,
          
          },
        }
    );

      gsap.fromTo(
        '.wf-title',
        {
          x: 50,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: 2,
          delay: 0.5,
          scrollTrigger: {
            trigger: '.wf-title',
            start: 'top 80%',
            end: '+=300',
            scrub: 1,
        
          },
        }
    );

      gsap.fromTo(
        '.ws-title',
        {
          x: -50,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: 2,
          delay: 0.5,
          scrollTrigger: {
            trigger: '.ws-title',
            start: 'top 80%',
            end: '+=300',
            scrub: 1,
        
          },
        }
    );

    // ---------------------- GSAP Animation for Men Section -------------------------------- //
      gsap.fromTo(
        '.card1',
        {
          x: 50,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          duration: 2,
          delay: 0.5,
          scrollTrigger: {
            trigger: '.card1',
            start: 'top 80%',
            end: '+=300',
            scrub: 1,
        
          },
        }
      );

  }, [currentVideo]);

  const playVideo = (videoId) => {
    if (currentVideo && currentVideo !== videoId) {
      const prevVideo = document.getElementById(currentVideo);
      if (prevVideo) {
        prevVideo.pause();
      }
    }
    const video = document.getElementById(videoId);
    if (video) {
      video.play().catch((error) => {
        console.error("Autoplay failed: ", error);
      });
      setCurrentVideo(videoId);
    }
  };

  return (
    <>
    <div className="main">
      <section className="first-section">       

          <div className="bg">
            <video autoPlay loop muted>
              <source src={vd6}  type="video/mp4" />
            </video>
          </div>

          <div className="contenu">
            <span className="title">Iver</span>
            <span className="sub-title">For our Ladies</span>
          </div>
          
          <div class="wrap">
            <Link class="button" to='/women'>Discover</Link>
          </div>
              
          
        </section>

        <div className="w-info">
          <div className="w-gallery">
            <div className="pic1 dark pi">
              <img src={image1}/>
            </div>
            <div className="pic1 dark">
              <img src={image2}/>
            </div>
            <div className="pic1 dark">
              <img src={image3}/>
            </div>
            <div className="pic1 dark">
              <img src={image4}/>
            </div>
          </div>
          <div className="w-title">
            <h1 className="wf-title">seasons <span >collections</span></h1>
            <div className="w-parag">
            <p className="ws-title">Embrace the ever-changing seasons with style that mirrors your mood and personality</p>
          </div>
          </div>

        </div>

        

        <section className="first-section">
          <div className="bg">
            <video id="background-video2" loop>
              <source src={vd7} type="video/mp4" />
            </video>
          </div>

          <div className="contenu">
            <span className="title">Iver</span><br></br>
            <span className="sub-title">For our Chaps</span>
          </div>

          <button className="btn-vd" onClick={() => playVideo("background-video2")}>
              <AiFillCaretRight/>
          </button>
          <Link className="btn-disc" to="/man">Discover</Link>
        </section>

        <div className="m-info">

          <div className="m-title">
            <h1>New Arivalls</h1>
          </div>
          <div className="cards">
            <Card className="card1" sx={{ width: 285, maxHeight: 355 }}>
                <CardMedia sx={{ height: 300 }} image={mpic1} />
                <CardActions className="card-action">
                  <Button size="small">Learn More</Button>
                </CardActions>
            </Card>
            <Card className="card1" sx={{ width: 285, maxHeight: 355 }}>
                <CardMedia
                  sx={{ height: 300 }}
                  image={mpic2}
                  title="green iguana"
                />
                <CardActions className="card-action">
                  <Button size="small"><Link to='/shop'>Learn More</Link></Button>
                </CardActions>
            </Card>
            <Card className="card1 card2" sx={{ width: 285, maxHeight: 355 }}>
                <CardMedia
                  sx={{ height: 300 }}
                  image={mpic3}
                  title="green iguana"
                />
                <CardActions className="card-action">
                  <Button size="small">Learn More</Button>
                </CardActions>
            </Card>
            <Card className="card1 card2" sx={{ width: 285, maxHeight: 355 }}>
                <CardMedia
                  sx={{ height: 300 }}
                  image={mpic1}
                  title="green iguana"
                />
                <CardActions className="card-action">
                  <Button size="small">Learn More</Button>
                </CardActions>
            </Card>


          </div>
        </div>

      <section className="first-section">
          <div className="bg">
            <video id="background-video3" loop>
              <source src={vd8} type="video/mp4" />
            </video>
          </div>

          <div className="contenu">
            <span className="title">Iver</span><br></br>
            <span className="sub-title">For our Youths</span>
          </div>

          <button className="btn-vd" onClick={() => playVideo("background-video3")}>
              <AiFillCaretRight/>
          </button>
          <button className="btn-disc">
              Discover
          </button>
        </section>
         
       
     
        </div>
    </>
  );
}
export default Parallax3;
